import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  AlertTitle,
  Select,
  MenuItem,
  ListSubheader,
  type SelectChangeEvent,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { type ISelectObject } from "@/types/select";
import { type IStationProviderWithLineObject } from "../types";
import { SectionPaper } from "@/components/Paper";
import { SectionTitle } from "@/components/Title";
import { Divider } from "@/components/Divider";
import { Label } from "@/components/Label";
import { MultiSelect } from "@/components/Select";
import { RowSpacingWrapper } from "@/components/Wrapper/RowSpacingWrapper";
import { Checkbox, CheckboxGroup } from "@/components/Checkbox";
import { Button, ButtonVariantOption } from "@/components/Button";
import { DateRangePicker } from "@/components/DatePicker";
import { TextField as CustomTextField } from "@/components/TextField";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { AutocompleteMultiStationSelect } from "@/components/Select/AutocompleteMultiStationSelect";
import { useEffect } from "react";

interface Props {
  setPrefectures: React.Dispatch<React.SetStateAction<string[]>>;
  setCities: React.Dispatch<React.SetStateAction<string[]>>;
  setLegalAffairsBureauRequestDateStart: React.Dispatch<
    React.SetStateAction<Date | null>
  >;
  setLegalAffairsBureauRequestDateEnd: React.Dispatch<
    React.SetStateAction<Date | null>
  >;
  setReceptionReasons: React.Dispatch<React.SetStateAction<string[]>>;
  setRealEstateBookTypeTandoku: React.Dispatch<React.SetStateAction<boolean>>;
  setRealEstateBookTypeRensakiRenzoku: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setRealEstateTypeTochi: React.Dispatch<React.SetStateAction<boolean>>;
  setRealEstateTypeKutate: React.Dispatch<React.SetStateAction<boolean>>;
  setRealEstateTypeTatemono: React.Dispatch<React.SetStateAction<boolean>>;
  setRealEstateTypeKyotan: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTowerApartment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOneRoom: React.Dispatch<React.SetStateAction<boolean>>;
  setSotoRange: React.Dispatch<
    React.SetStateAction<Record<string, number | null>>
  >;
  setEstimateAcreageRange: React.Dispatch<
    React.SetStateAction<Record<string, number | null>>
  >;
  setEstimateLandPriceRange: React.Dispatch<
    React.SetStateAction<Record<string, number | null>>
  >;
  setApproximateEstimatePriceRange: React.Dispatch<
    React.SetStateAction<Record<string, number | null>>
  >;
  setResidenceOptions: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setCommercialOptions: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setIndustryOptions: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setYosekiRatioRange: React.Dispatch<
    React.SetStateAction<Record<string, number | null>>
  >;
  setKenpeiRatioRange: React.Dispatch<
    React.SetStateAction<Record<string, number | null>>
  >;
  setHasTrade: React.Dispatch<React.SetStateAction<boolean>>;
  setBuildDateStart: React.Dispatch<React.SetStateAction<Date | null>>;
  setBuildDateEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  setPropertyName: React.Dispatch<React.SetStateAction<string>>;
  setStationSelectedValues: React.Dispatch<
    React.SetStateAction<ISelectObject[]>
  >;
  setStationLine: React.Dispatch<React.SetStateAction<number>>;
  setWalk: React.Dispatch<React.SetStateAction<number>>;
  handleSearch: () => void;
  cities: string[];
  prefectures: string[];
  legalAffairsBureauRequestDateStart: Date | null;
  legalAffairsBureauRequestDateEnd: Date | null;
  receptionReasons: string[];
  realEstateBookTypeTandoku: boolean;
  realEstateBookTypeRensakiRenzoku: boolean;
  realEstateTypeTochi: boolean;
  realEstateTypeKutate: boolean;
  realEstateTypeTatemono: boolean;
  realEstateTypeKyotan: boolean;
  isTowerApartment: boolean;
  isOneRoom: boolean;
  sotoRange: Record<string, number | null>;
  estimateAcreageRange: Record<string, number | null>;
  estimateLandPriceRange: Record<string, number | null>;
  approximateEstimatePriceRange: Record<string, number | null>;
  residenceOptions: Record<string, boolean>;
  commercialOptions: Record<string, boolean>;
  industryOptions: Record<string, boolean>;
  yosekiRatioRange: Record<string, number | null>;
  kenpeiRatioRange: Record<string, number | null>;
  hasTrade: boolean;
  buildDateStart: Date | null;
  buildDateEnd: Date | null;
  propertyName: string;
  stationSelectedValues: ISelectObject[];
  cityOptions: ISelectObject[];
  prefectureOptions: ISelectObject[];
  stationLine: number;
  walk: number;
  startDate: Date;
  minDate: Date;
  maxDate: Date;
  residenceAllChecked: boolean;
  commercialAllChecked: boolean;
  industryAllChecked: boolean;
  stationOptions: ISelectObject[];
  receptionReasonOptions: ISelectObject[];
  stationLineOptions: IStationProviderWithLineObject[];
  handleInputChange: (
    type: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => void;
  preventNonNumericValueWhenKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  preventNonNumericValueWhenPaste: (
    e: React.ClipboardEvent<HTMLDivElement>
  ) => void;
  handleResidenceChangeAll: (event: boolean) => void;
  handleOptionChange: (event: boolean, type: string, name: string) => void;
  handleCommercialChangeAll: (event: boolean) => void;
  handleIndustryChangeAll: (event: boolean) => void;
  handleSelectChange: (
    key: string,
    event: SelectChangeEvent<number | null>,
    handleTarget: string
  ) => void;
}

export const SearchCondition: React.FC<Props> = (props) => {
  const {
    cities,
    prefectures,
    commercialAllChecked,
    legalAffairsBureauRequestDateStart,
    legalAffairsBureauRequestDateEnd,
    receptionReasons,
    realEstateBookTypeTandoku,
    realEstateBookTypeRensakiRenzoku,
    realEstateTypeTochi,
    realEstateTypeKutate,
    realEstateTypeTatemono,
    realEstateTypeKyotan,
    isTowerApartment,
    isOneRoom,
    stationLineOptions,
    stationSelectedValues,
    residenceOptions,
    commercialOptions,
    industryOptions,
    yosekiRatioRange,
    kenpeiRatioRange,
    buildDateStart,
    buildDateEnd,
    propertyName,
    stationLine,
    walk,
    cityOptions,
    prefectureOptions,
    startDate,
    minDate,
    maxDate,
    receptionReasonOptions,
    residenceAllChecked,
    industryAllChecked,
    hasTrade,
    setPrefectures,
    setCities,
    setWalk,
    handleSearch,
    setIsTowerApartment,
    setIsOneRoom,
    setBuildDateStart,
    setBuildDateEnd,
    setPropertyName,
    setStationSelectedValues,
    setStationLine,
    setRealEstateTypeKyotan,
    setRealEstateBookTypeRensakiRenzoku,
    setRealEstateBookTypeTandoku,
    setRealEstateTypeKutate,
    setRealEstateTypeTatemono,
    setRealEstateTypeTochi,
    setLegalAffairsBureauRequestDateStart,
    setLegalAffairsBureauRequestDateEnd,
    handleInputChange,
    preventNonNumericValueWhenKeyDown,
    preventNonNumericValueWhenPaste,
    setReceptionReasons,
    handleResidenceChangeAll,
    handleOptionChange,
    handleCommercialChangeAll,
    handleIndustryChangeAll,
    handleSelectChange,
    setHasTrade,
  } = props;
  const YOSEKI_RATIO_MAX = 1300;
  const YOSEKI_RATIO_LIST = [
    50,
    60,
    80,
    100,
    150,
    ...Array((YOSEKI_RATIO_MAX - 200) / 100 + 1)
      .fill(undefined)
      .map((_, index) => 200 + index * 100),
  ];

  // 建ぺい率のセレクトボックスで選択可能な最大値、最小値を定義する
  const KENPEI_RATIO_MAX = 80;
  const KENPEI_RATIO_MIN = 30;

  useEffect(() => {
    // 市区町村、鉄道路線の選択状態が変化したら駅セレクトボックスは初期化する
    setStationSelectedValues([]);
  }, [cities, stationLine]);

  return (
    <SectionPaper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SectionTitle>絞り込み</SectionTitle>
      </Box>
      <Box sx={{ mx: 2 }}>
        <Divider />
      </Box>

      {/* 不動産登記情報 */}
      <Box sx={{ mx: 2, mt: 2, mb: 1 }}>
        <Label>不動産登記情報</Label>
        <AlertTitle
          sx={{ fontWeight: "bold", color: "red", fontSize: "0.85rem" }}
        >
          ・市区町村は1エリア以上10エリア以下で選択してください
        </AlertTitle>
        <AlertTitle
          sx={{ fontWeight: "bold", color: "red", fontSize: "0.85rem" }}
        >
          ・登記原因は1つ選択してください
        </AlertTitle>
        <AlertTitle
          sx={{ fontWeight: "bold", color: "red", fontSize: "0.85rem" }}
        >
          ・法務局受付日検索期間は1年以内で選択してください
        </AlertTitle>
        <RowSpacingWrapper>
          <Box sx={{ my: 1 }}>
            <MultiSelect
              label="都道府県"
              value={prefectures}
              onChange={setPrefectures}
              options={prefectureOptions}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <MultiSelect
              label="市区町村 *"
              value={cities}
              onChange={setCities}
              options={cityOptions}
              maxSelect={10}
            />
          </Box>
          <Box sx={{ display: "flex", my: 1 }}>
            <DateRangePicker
              startDateLabel="法務局受付日  開始"
              startDate={legalAffairsBureauRequestDateStart}
              onChangeStartDate={setLegalAffairsBureauRequestDateStart}
              endDateLabel="法務局受付日  終了"
              endDate={legalAffairsBureauRequestDateEnd}
              onChangeEndDate={setLegalAffairsBureauRequestDateEnd}
              minDate={minDate}
              maxDate={maxDate}
              defaultCalendarMonth={startDate}
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <MultiSelect
              label="登記原因"
              value={receptionReasons}
              onChange={setReceptionReasons}
              options={receptionReasonOptions}
              maxSelect={1}
            />
          </Box>
        </RowSpacingWrapper>
      </Box>

      {/* 申請種別 */}
      <Box sx={{ mx: 2, mb: 2 }}>
        <CheckboxGroup label="申請種別">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              label="単独"
              checked={realEstateBookTypeTandoku}
              onChange={setRealEstateBookTypeTandoku}
            />
            <Checkbox
              label="連先・連続"
              checked={realEstateBookTypeRensakiRenzoku}
              onChange={setRealEstateBookTypeRensakiRenzoku}
            />
          </Box>
        </CheckboxGroup>
      </Box>

      {/* 不動産種別 */}
      <Box sx={{ mx: 2, mb: 2 }}>
        <CheckboxGroup label="不動産種別">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              label="土地"
              checked={realEstateTypeTochi}
              onChange={setRealEstateTypeTochi}
            />
            <Checkbox
              label="区分建物"
              checked={realEstateTypeKutate}
              onChange={setRealEstateTypeKutate}
            />
            <Checkbox
              label="建物"
              checked={realEstateTypeTatemono}
              onChange={setRealEstateTypeTatemono}
            />
            <Checkbox
              label="共担"
              checked={realEstateTypeKyotan}
              onChange={setRealEstateTypeKyotan}
            />
          </Box>
        </CheckboxGroup>
      </Box>

      <Accordion sx={{ mb: 1, boxShadow: "none", position: "static" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography sx={{ color: "blue" }}>さらに条件を追加する</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {/* 外 */}
          <Box sx={{ mx: 2, mb: 2, width: "800px" }}>
            <Label>外</Label>
            <Grid sx={{ display: "inline-block", top: "50%" }}>
              <TextField
                onChange={(e) => {
                  handleInputChange("min", e, "soto");
                }}
                onKeyDown={preventNonNumericValueWhenKeyDown}
                onPaste={preventNonNumericValueWhenPaste}
                size="small"
                sx={{ width: "100px", top: "50%" }}
                inputProps={{
                  min: 0,
                  type: "number",
                }}
              />
              以上 〜
              <TextField
                onChange={(e) => {
                  handleInputChange("max", e, "soto");
                }}
                onKeyDown={preventNonNumericValueWhenKeyDown}
                onPaste={preventNonNumericValueWhenPaste}
                size="small"
                sx={{ width: "100px", top: "50%" }}
                inputProps={{
                  min: 0,
                  type: "number",
                }}
              />
              以下
            </Grid>
          </Box>

          <div>
            {/* 推計地積 */}
            <Box sx={{ mx: 2, mb: 2 }}>
              <Grid sx={{ display: "inline-block", top: "50%" }}>
                <Label>推計地積</Label>
                <TextField
                  onChange={(e) => {
                    handleInputChange("min", e, "estimateAcreage");
                  }}
                  onKeyDown={preventNonNumericValueWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  size="small"
                  sx={{ width: "150px", top: "50%" }}
                  inputProps={{
                    min: 0,
                    type: "number",
                    step: "10",
                  }}
                />
                ㎡以上 〜
                <TextField
                  onChange={(e) => {
                    handleInputChange("max", e, "estimateAcreage");
                  }}
                  onKeyDown={preventNonNumericValueWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  size="small"
                  sx={{ width: "150px", top: "50%" }}
                  inputProps={{
                    min: 0,
                    type: "number",
                    step: "10",
                  }}
                />
                ㎡以下
              </Grid>
              <Grid
                sx={{
                  display: "inline-block",
                  top: "50%",
                  marginLeft: "20px",
                }}
              >
                <Label>公示価格</Label>
                <TextField
                  onChange={(e) => {
                    handleInputChange("min", e, "estimateLandPrice");
                  }}
                  onKeyDown={preventNonNumericValueWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  size="small"
                  sx={{ width: "150px", top: "50%" }}
                  inputProps={{
                    min: 0,
                    type: "number",
                    step: "10",
                  }}
                />
                円/㎡以上 〜
                <TextField
                  onChange={(e) => {
                    handleInputChange("max", e, "estimateLandPrice");
                  }}
                  onKeyDown={preventNonNumericValueWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  size="small"
                  sx={{ width: "150px", top: "50%" }}
                  inputProps={{
                    min: 0,
                    type: "number",
                    step: "10",
                  }}
                />
                円/㎡以下
              </Grid>
              <Grid
                sx={{
                  display: "inline-block",
                  top: "50%",
                  marginLeft: "20px",
                }}
              >
                <Label>概算価格（推計地積✕公示価格）</Label>
                <TextField
                  onChange={(e) => {
                    handleInputChange("min", e, "approximateEstimatePrice");
                  }}
                  onKeyDown={preventNonNumericValueWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  size="small"
                  sx={{ width: "150px", top: "50%" }}
                  inputProps={{
                    min: 0,
                    type: "number",
                    step: "1000000",
                  }}
                />
                円以上 〜
                <TextField
                  onChange={(e) => {
                    handleInputChange("max", e, "approximateEstimatePrice");
                  }}
                  onKeyDown={preventNonNumericValueWhenKeyDown}
                  onPaste={preventNonNumericValueWhenPaste}
                  size="small"
                  sx={{ width: "150px", top: "50%" }}
                  inputProps={{
                    min: 0,
                    type: "number",
                    step: "1000000",
                  }}
                />
                円以下
              </Grid>
            </Box>
            {/* 住居系 */}{" "}
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="用途地域">
                <Checkbox
                  label="住居系"
                  checked={residenceAllChecked}
                  onChange={handleResidenceChangeAll}
                />
                <FormGroup
                  id="areaUsePurpose"
                  sx={{
                    marginLeft: "30px",
                    display: "grid",
                    "grid-template-columns": "auto auto auto",
                  }}
                >
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="第一種低層住居専用地域（略：一低）"
                        checked={residenceOptions.ittei}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "ittei");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="第二種低層住居専用地域（略：二低）"
                        checked={residenceOptions.nitei}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "nitei");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="田園住居地域（略：田住）"
                        checked={residenceOptions.denju}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "denju");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="第一種中高層住居専用地域（略：一中高）"
                        checked={residenceOptions.icchuko}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "icchuko");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="第二種中高層専用住居地域（略：二中高）"
                        checked={residenceOptions.nichuko}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "nichuko");
                        }}
                      />
                    }
                  />
                  <div>{/* 場所を作るための空要素 */}</div>
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="第一種住居地域（略：一住）"
                        checked={residenceOptions.ichiju}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "ichiju");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="第二種住居地域（略：二住）"
                        checked={residenceOptions.niju}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "niju");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="準住居地域（略：準住）"
                        checked={residenceOptions.junju}
                        onChange={(e: boolean) => {
                          handleOptionChange(e, "residence", "junju");
                        }}
                      />
                    }
                  />
                </FormGroup>
              </CheckboxGroup>
            </Box>
            {/* 商業系 */}
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="">
                <Checkbox
                  label="商業系"
                  checked={commercialAllChecked}
                  onChange={handleCommercialChangeAll}
                />
                <FormGroup
                  sx={{
                    marginLeft: "30px",
                    display: "grid",
                    "grid-template-columns": "auto auto auto",
                  }}
                >
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="近隣商業地域（略：近商）"
                        checked={commercialOptions.kinsyo}
                        onChange={(e) => {
                          handleOptionChange(e, "commercial", "kinsyo");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="商業地域（略：商業）"
                        checked={commercialOptions.syogyo}
                        onChange={(e) => {
                          handleOptionChange(e, "commercial", "syogyo");
                        }}
                      />
                    }
                  />
                </FormGroup>
              </CheckboxGroup>
            </Box>
            {/* 工業系 */}
            <Box sx={{ mx: 2, mb: 2 }}>
              <CheckboxGroup label="">
                <Checkbox
                  label="工業系"
                  checked={industryAllChecked}
                  onChange={handleIndustryChangeAll}
                />
                <FormGroup
                  sx={{
                    marginLeft: "30px",
                    display: "grid",
                    "grid-template-columns": "auto auto auto",
                  }}
                >
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="準工業地域（略：準工）"
                        checked={industryOptions.junko}
                        onChange={(e) => {
                          handleOptionChange(e, "industry", "junko");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="工業地域（略：工業）"
                        checked={industryOptions.kogyo}
                        onChange={(e) => {
                          handleOptionChange(e, "industry", "kogyo");
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        label="工業専用地域（略：工専）"
                        checked={industryOptions.kosen}
                        onChange={(e) => {
                          handleOptionChange(e, "industry", "kosen");
                        }}
                      />
                    }
                  />
                </FormGroup>
              </CheckboxGroup>
            </Box>
          </div>

          {/* 容積率・建ぺい率 */}
          <Box sx={{ mx: 2, mb: 2 }}>
            <Grid sx={{ display: "inline-block", top: "50%" }}>
              <Label>容積率</Label>
              {/* yosekiRatioRange.minの初期値はnullです */}
              {/* Selectのvalueにnullがうまく設定できなかったため */}
              {/* 回避策としてnullの場合は-1をvalueに代入しています */}
              <Select
                value={yosekiRatioRange.min ?? -1}
                onChange={(e) => {
                  handleSelectChange("min", e, "yosekiRatio");
                }}
                size="small"
                sx={{ width: "150px", top: "50%" }}
              >
                <MenuItem value={-1}>指定なし</MenuItem>
                {YOSEKI_RATIO_LIST.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}%
                  </MenuItem>
                ))}
              </Select>
              以上 〜
              <Select
                value={yosekiRatioRange.max ?? -1}
                onChange={(e) => {
                  handleSelectChange("max", e, "yosekiRatio");
                }}
                size="small"
                sx={{ width: "150px", top: "50%" }}
              >
                <MenuItem value={-1}>指定なし</MenuItem>
                {YOSEKI_RATIO_LIST.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}%
                  </MenuItem>
                ))}
              </Select>
              以下
            </Grid>
            <Grid
              sx={{
                display: "inline-block",
                top: "50%",
                marginLeft: "20px",
              }}
            >
              <Label>建ぺい率</Label>
              <Select
                value={kenpeiRatioRange.min ?? -1}
                onChange={(e) => {
                  handleSelectChange("min", e, "kenpeiRatio");
                }}
                size="small"
                sx={{ width: "150px", top: "50%" }}
              >
                <MenuItem value={-1}>指定なし</MenuItem>
                {Array((KENPEI_RATIO_MAX - KENPEI_RATIO_MIN) / 10 + 1)
                  .fill(undefined)
                  .map((_, index) => (
                    <MenuItem key={index} value={KENPEI_RATIO_MIN + index * 10}>
                      {KENPEI_RATIO_MIN + index * 10}%
                    </MenuItem>
                  ))}
              </Select>
              以上 〜
              <Select
                value={kenpeiRatioRange.max ?? -1}
                onChange={(e) => {
                  handleSelectChange("max", e, "kenpeiRatio");
                }}
                size="small"
                sx={{ width: "150px", top: "50%" }}
              >
                <MenuItem value={-1}>指定なし</MenuItem>
                {Array((KENPEI_RATIO_MAX - KENPEI_RATIO_MIN) / 10 + 1)
                  .fill(undefined)
                  .map((_, index) => (
                    <MenuItem key={index} value={KENPEI_RATIO_MIN + index * 10}>
                      {KENPEI_RATIO_MIN + index * 10}%
                    </MenuItem>
                  ))}
              </Select>
              以下
            </Grid>
          </Box>

          {/* マンション名・築年数 */}
          <Box sx={{ mx: 2, display: "flex", flexDirection: "row" }}>
            <Box>
              <Label>マンション名</Label>
              <CustomTextField
                id="propertyName"
                label="マンション名"
                value={propertyName}
                onChange={setPropertyName}
              />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Label>築年月</Label>
              <Box sx={{ display: "flex" }}>
                <DateRangePicker
                  startDateLabel="以降"
                  startDate={buildDateStart}
                  onChangeStartDate={setBuildDateStart}
                  endDateLabel="以前"
                  endDate={buildDateEnd}
                  maxDate={maxDate}
                  onChangeEndDate={setBuildDateEnd}
                  inputFormat="yyyy年"
                  views={["year"]}
                />
              </Box>
            </Box>

            {/* タワマン・ワンルーム */}
            <Box sx={{ ml: 2 }}>
              <CheckboxGroup label="">
                <Box sx={{ display: "flex", flexDirection: "row", mt: 5 }}>
                  <Checkbox
                    label="タワマン"
                    checked={isTowerApartment}
                    onChange={setIsTowerApartment}
                  />
                  <Checkbox
                    label="ワンルーム"
                    checked={isOneRoom}
                    onChange={setIsOneRoom}
                  />
                </Box>
              </CheckboxGroup>
            </Box>
          </Box>

          {/* 駅 */}
          <Box sx={{ mx: 2, my: 2 }}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <Label>駅</Label>
              </Grid>
              <Grid item xs={"auto"}>
                路線名:
              </Grid>
              <Grid item xs={"auto"}>
                <Select
                  value={stationLine}
                  onChange={(e) => {
                    setStationLine(Number(e.target.value));
                  }}
                  size="small"
                  sx={{
                    width: "340px",
                    minHeight: "56px",
                  }}
                >
                  <MenuItem value={-1}>指定なし</MenuItem>
                  {stationLineOptions.map((provider) => {
                    // 鉄道事業者名
                    const company = (
                      <ListSubheader>{provider.companyName}</ListSubheader>
                    );
                    // 路線名一覧を作る
                    const lines = provider.lines.map((line) => {
                      return (
                        <MenuItem key={line.id} value={line.id}>
                          {line.lineName}
                        </MenuItem>
                      );
                    });
                    return [company, ...lines];
                  })}
                </Select>
              </Grid>
              <Grid item xs={"auto"}>
                <Typography>駅名:</Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <AutocompleteMultiStationSelect
                  placeholder={"駅名を選択してください（複数選択可）"}
                  lineId={stationLine}
                  cityIds={cities}
                  stationSelectedValues={stationSelectedValues}
                  setStationSelectedValues={setStationSelectedValues}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: "inline-block" }}>
              <Label>徒歩</Label>
            </Grid>
            <Grid item xs={12}>
              <Select
                value={walk}
                onChange={(e) => {
                  setWalk(Number(e.target.value));
                }}
                size="small"
                sx={{ width: "150px", minHeight: "56px", top: "50%" }}
              >
                <MenuItem key={-1} value={-1}>
                  指定なし
                </MenuItem>
                {[...Array(60).keys()]
                  .filter((val) => val >= 1)
                  .map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}分以内
                    </MenuItem>
                  ))}
                <MenuItem key={60} value={60}>
                  60分以上
                </MenuItem>
              </Select>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* 検索 */}
      <Box sx={{ mx: 2 }}>
        <Divider />
      </Box>

      <Box sx={{ my: 3, mr: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          label="この条件で検索"
          onClick={() => {
            // トグルオンの状態で検索ボタン押した時にトグルボタンをオフにする
            // hasTradeの状態が変わるとhandleSearchを実行される
            if (hasTrade) {
              setHasTrade(false);
            } else {
              handleSearch();
            }
          }}
          variant={ButtonVariantOption.Contained}
          size={SizingWrapperStyle.SMALL}
        />
      </Box>
    </SectionPaper>
  );
};
