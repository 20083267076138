import { type BaseMenuData } from "@/configs/menuDataDefinition";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export interface CategoryContainerProps extends BaseMenuData {
  children: React.ReactNode;
}

export const CategoryContainer: React.FC<CategoryContainerProps> = (props) => {
  const getFontColor = (level: 1 | 2): string => {
    const isOddLevel = level % 2 === 1;
    const blueColor = "#1976D2";
    const blackColor = "#000000";

    return isOddLevel ? blackColor : blueColor;
  };

  const theme = createTheme();
  theme.typography.h4 = {
    fontSize: "24px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  };
  theme.typography.h5 = {
    fontSize: "20px",
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  };
  return (
    <>
      <Box bgcolor={props?.bgColor ?? "#FFFFFF"} p={props.level === 1 ? 2 : 3}>
        <ThemeProvider theme={theme}>
          <Typography
            variant={props.level === 1 ? "h4" : "h5"}
            color={getFontColor(props.level)}
            fontWeight={700}
            p={"0 0 .7rem 0"}
          >
            {props.title}
          </Typography>
        </ThemeProvider>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2, md: 4 }}
          useFlexGap
          flexWrap="wrap"
        >
          {props.children}
        </Stack>
      </Box>
    </>
  );
};
