import { z } from "zod";

// Zodスキーマ定義
const AcquireBookFormDataSchema = z.object({
  identifyName: z.string().min(1, "識別名は必須です"),
});

// 定義したZodのスキーマをTypescriptの型に変換
type AcquireBookFormData = z.infer<typeof AcquireBookFormDataSchema>;

export { AcquireBookFormDataSchema, type AcquireBookFormData };
