import { SectionTitle } from "@/components/Title";
import { SizingWrapper } from "@/components/Wrapper";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import type { SearchSectionProps } from "../types";
import { CheckboxGroup } from "@/components/Checkbox";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";

export const searchConditionSchema = z.object({
  prefectures: z
    .array(z.string())
    .min(1, "少なくとも1つの都道府県を選択してください"),
  location: z.string().min(1, "所在を入力してください"),
  pictureTypeZenbujiko: z.boolean(),
  pictureTypeShoyushajiko: z.boolean(),
  ownerName: z.string().optional(),
  ownerAddress: z.string().optional(),
  isExistMochibun: z.boolean(),
  isNotExistMochibun: z.boolean(),
});

export type SearchConditionFormSchema = z.infer<typeof searchConditionSchema>;

export const SearchSection: React.FC<SearchSectionProps> = ({
  handleSearch,
  prefectures,
}) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SearchConditionFormSchema>({
    mode: "all",
    defaultValues: {
      prefectures: [],
      location: "",
      pictureTypeShoyushajiko: false,
      pictureTypeZenbujiko: false,
      ownerName: "",
      ownerAddress: "",
      isExistMochibun: true,
      isNotExistMochibun: true,
    },
    resolver: zodResolver(searchConditionSchema),
  });

  const onSubmit = (data: SearchConditionFormSchema): void => {
    handleSearch(data);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SectionTitle>検索</SectionTitle>
      </Box>

      <Box sx={{ mx: 2, mb: 2 }}>
        <Divider />
      </Box>

      <form
        onSubmit={
          handleSubmit(onSubmit) as React.FormEventHandler<HTMLFormElement>
        }
      >
        {/* 都道府県 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <SizingWrapper>
            <Controller
              name="prefectures"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <FormControl fullWidth>
                    <InputLabel
                      id="prefecture-multiple-checkbox-label"
                      required
                      error={!!errors.prefectures}
                    >
                      都道府県
                    </InputLabel>
                    <Select
                      {...field}
                      id="prefecture-multiple-checkbox"
                      error={!!errors.prefectures}
                      input={
                        <OutlinedInput
                          label="都道府県"
                          id="prefecture-multiple-chip"
                        />
                      }
                      labelId="prefecture-multiple-checkbox-label"
                      multiple
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => {
                            const selectPrefecture = prefectures.find(
                              (prefecture) => prefecture.prefCode === value
                            );

                            return (
                              <Chip
                                key={selectPrefecture?.prefCode}
                                label={selectPrefecture?.name}
                                sx={{ cursor: "pointer" }}
                              />
                            );
                          })}
                        </Box>
                      )}
                      endAdornment={
                        field.value.length > 0 ? (
                          <IconButton
                            sx={{
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              reset({
                                prefectures: [],
                                location: getValues("location"),
                                pictureTypeShoyushajiko: getValues(
                                  "pictureTypeShoyushajiko"
                                ),
                                pictureTypeZenbujiko: getValues(
                                  "pictureTypeZenbujiko"
                                ),
                                ownerName: getValues("ownerName"),
                                ownerAddress: getValues("ownerAddress"),
                                isExistMochibun: getValues("isExistMochibun"),
                                isNotExistMochibun:
                                  getValues("isNotExistMochibun"),
                              });
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null
                      }
                    >
                      {prefectures.map((prefecture) => (
                        <MenuItem
                          key={prefecture.prefCode}
                          value={prefecture.prefCode}
                        >
                          <Checkbox
                            checked={field.value.includes(prefecture.prefCode)}
                          />
                          <ListItemText primary={prefecture.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {errors?.prefectures?.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
            />
          </SizingWrapper>
        </Box>

        {/* 所在 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="location"
                    label="所在"
                    type="text"
                    required
                    error={!!errors.location}
                    helperText={errors.location ? errors.location.message : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {/* 所有者名・所有者住所 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="ownerName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="ownerName"
                    label="所有者名"
                    type="text"
                    error={!!errors.ownerName}
                    helperText={
                      errors.ownerName ? errors.ownerName.message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="ownerAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="ownerAddress"
                    label="所有者住所"
                    type="text"
                    error={!!errors.ownerAddress}
                    helperText={
                      errors.ownerAddress ? errors.ownerAddress.message : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {/* 事項種別 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <CheckboxGroup label="事項種別">
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Controller
                    name="pictureTypeZenbujiko"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} name="pictureTypeZenbujiko" />
                    )}
                  />
                }
                label="全部事項"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="pictureTypeShoyushajiko"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} name="pictureTypeShoyushajiko" />
                    )}
                  />
                }
                label="所有者事項"
              />
            </Box>
          </CheckboxGroup>
        </Box>

        {/* 持分の有無 */}
        <Box sx={{ mx: 2, mb: 2 }}>
          <CheckboxGroup label="持分の有無">
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Controller
                    name="isExistMochibun"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        name="isExistMochibun"
                        defaultChecked={true}
                      />
                    )}
                  />
                }
                label="あり"
              />
              <FormControlLabel
                control={
                  <Controller
                    name="isNotExistMochibun"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        name="isNotExistMochibun"
                        defaultChecked={true}
                      />
                    )}
                  />
                }
                label="なし"
              />
            </Box>
          </CheckboxGroup>
        </Box>

        {/* ボタン */}
        <Box
          sx={{
            mr: 2,
            mb: 3,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit" variant="outlined">
            検索
          </Button>
        </Box>
      </form>
    </>
  );
};
