import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  type SelectChangeEvent,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import {
  Button,
  ButtonVariantOption,
  SubmitButton,
} from "../../components/Button";
import { SizingWrapper, SizingWrapperStyle } from "@/components/Wrapper";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { KeyboardArrowLeft } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";
import { BlueSectionPaper } from "./components";
import {
  CheckboxesToSelectBookOptions,
  getPrefectureMenuItems,
} from "@/utils/utilsAcquireBook";
import { type CityListResponse } from "./types";
import { useValidator } from "./hooks/useValidator";
import { useFeatureFlags } from "@/configs/featureFlag";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface IAcquireBookPresenterProps {
  handleSubmit: (
    prefecture: string,
    city: string,
    locationWithoutCity: string,
    locationFull: string,
    chibanKaokuNumber: string,
    pastDays: number,
    realEstateType: string,
    requestOptions: Record<string, Record<string, boolean>>,
    withOwnerAnalyze: boolean,
    identificationName: string,
    cancelLoading: () => void
  ) => void;
  prefecture: string;
  setPrefecture: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  chibanKaokuNumber: string;
  setChibanKaokuNumber: React.Dispatch<React.SetStateAction<string>>;
  pastDays: number;
  setPastDays: React.Dispatch<React.SetStateAction<number>>;
  locationWithoutCity: string;
  setLocationWithoutCity: React.Dispatch<React.SetStateAction<string>>;
  realEstateTypeEng: string;
  setRealEstateTypeEng: React.Dispatch<React.SetStateAction<string>>;
  requestOptions: Record<string, Record<string, boolean>>;
  setRequestOptions: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, boolean>>>
  >;
  cityList: CityListResponse;
}
export const AcquireBookPresenter: React.FC<IAcquireBookPresenterProps> = (
  props
) => {
  const {
    handleSubmit,
    prefecture,
    setPrefecture,
    city,
    setCity,
    locationWithoutCity,
    setLocationWithoutCity,
    chibanKaokuNumber,
    setChibanKaokuNumber,
    pastDays,
    setPastDays,
    realEstateTypeEng,
    setRealEstateTypeEng,
    requestOptions,
    setRequestOptions,
    cityList,
  } = props;

  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = React.useState(false);
  const [withOwnerAnalyze, setWithOwnerAnalyze] = React.useState(false);

  // バリデーションのためのフックを導入する
  const validator = useValidator();

  // Zodスキーマ定義
  const FormDataSchema = z.object({
    identifyName: z.string().min(1, "識別名は必須です"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type FormData = z.infer<typeof FormDataSchema>;

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const reactHookForm = useForm<FormData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
    },
    resolver: zodResolver(FormDataSchema),
  });

  // 都道府県セレクトボックス変更時の処理
  const handlePrefecture = (event: SelectChangeEvent<string>): void => {
    setPrefecture(event.target.value);
    validator.pref.validate(event.target.value);
    // 市区町村selectBoxの中身が変わるのでselectBoxを初期状態に戻す
    setCity("");
  };

  // 市区町村セレクトボックス変更時の処理
  const handleCity = (event: SelectChangeEvent<string>): void => {
    setCity(event.target.value);
    validator.city.validate(event.target.value);
  };

  // 市区町村のセレクトボックスの選択項目を生成する
  const getCityMenuItems = (
    cityListResponse: CityListResponse
  ): React.ReactNode | undefined => {
    const cityList = cityListResponse.list;
    if (cityList.length > 0) {
      return cityList.map((value) => {
        return (
          <MenuItem key={value.id} value={value.cityCode}>
            {value.name}
          </MenuItem>
        );
      });
    }
  };

  const { ownerInfoViaScraping, mypageTop } = useFeatureFlags();
  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <PersonIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>不動産登記/図面取得</PageTitle>
          </Box>
          <Box sx={{ ml: 10 }}>
            <ul>
              <li>最新の不動産登記/図面を取得いたします</li>
              <li>取得済みの場合は、課金が発生いたしません</li>
            </ul>
          </Box>
        </Box>
        <SectionPaper>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} justifyContent={"left"}>
              <Grid item>
                <TextField
                  {...reactHookForm.register("identifyName")}
                  error={Boolean(reactHookForm.formState.errors.identifyName)}
                  helperText={
                    reactHookForm.formState.errors.identifyName?.message
                  }
                  label="識別名"
                  sx={{ width: "500px" }}
                />
              </Grid>
              <Grid item>
                <Box display="flex">
                  <TextField
                    label="前回取得日からの経過日数を指定する"
                    value={pastDays}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const val = Number(event.target.value);
                      if (isNaN(val)) return;
                      setPastDays(val);
                    }}
                    sx={{ width: "300px" }}
                  />
                  <Stack>
                    <Tooltip
                      title="取得済の場合でも経過日数を過ぎたものは再取得いたします"
                      placement="left"
                    >
                      <HelpIcon />
                    </Tooltip>
                    日
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={"left"}>
              <Grid item>
                <Box sx={{ my: 1 }}>
                  <SizingWrapper>
                    <FormControl fullWidth error={validator.pref.state.error}>
                      <InputLabel id="select-label">都道府県</InputLabel>
                      <Select
                        labelId="select-label"
                        label="都道府県"
                        value={prefecture}
                        onChange={handlePrefecture}
                      >
                        {getPrefectureMenuItems()}
                      </Select>
                      <FormHelperText>
                        {validator.pref.state.helperTextWhenError}
                      </FormHelperText>
                    </FormControl>
                  </SizingWrapper>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ my: 1 }}>
                  <SizingWrapper>
                    <FormControl fullWidth error={validator.city.state.error}>
                      <InputLabel id="select-label">市区町村</InputLabel>
                      <Select
                        labelId="select-label"
                        label="市区町村"
                        value={city}
                        onChange={handleCity}
                      >
                        {getCityMenuItems(cityList)}
                      </Select>
                      <FormHelperText>
                        {validator.city.state.helperTextWhenError}
                      </FormHelperText>
                    </FormControl>
                  </SizingWrapper>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ my: 1 }}>
                  <SizingWrapper>
                    <FormControl
                      fullWidth
                      error={validator.realEstateType.state.error}
                    >
                      <InputLabel id="select-label">不動産種別</InputLabel>
                      <Select
                        labelId="select-label"
                        label="不動産種別"
                        value={realEstateTypeEng}
                        onChange={(event: SelectChangeEvent<string>) => {
                          setRealEstateTypeEng(event.target.value);
                          validator.realEstateType.validate(event.target.value);
                        }}
                      >
                        <MenuItem value={"LAND"}>土地</MenuItem>
                        <MenuItem value={"BUILDING"}>建物</MenuItem>
                        <MenuItem value={"CONDOMINIUM"}>区分建物</MenuItem>
                      </Select>
                      <FormHelperText>
                        {validator.realEstateType.state.helperTextWhenError}
                      </FormHelperText>
                    </FormControl>
                  </SizingWrapper>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={"left"}>
              <Grid item>
                <TextField
                  error={validator.locationWithoutCity.state.error}
                  label="市町村以下"
                  helperText={
                    validator.locationWithoutCity.state.helperTextWhenError
                  }
                  value={locationWithoutCity}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLocationWithoutCity(event.target.value);
                    validator.locationWithoutCity.validate(event.target.value);
                  }}
                  sx={{ width: "500px" }}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={validator.chibanKaokuNumber.state.error}
                  label="地番または家屋番号"
                  helperText={
                    validator.chibanKaokuNumber.state.helperTextWhenError
                  }
                  value={chibanKaokuNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setChibanKaokuNumber(event.target.value);
                    validator.chibanKaokuNumber.validate(event.target.value);
                  }}
                  sx={{ width: "500px" }}
                />
              </Grid>
            </Grid>

            <Typography sx={{ color: "blue", my: 3, fontSize: "20px" }}>
              取得したい謄本/図面を選択してください
            </Typography>

            <BlueSectionPaper
              error={validator.checkOptions.state.error}
              helperText={validator.checkOptions.state.helperTextWhenError}
            >
              <CheckboxesToSelectBookOptions
                requestOptions={requestOptions}
                setRequestOptions={setRequestOptions}
                realEstateTypeEng={realEstateTypeEng}
                onChange={(
                  event: boolean,
                  key: string,
                  requestOptions: Record<string, Record<string, boolean>>
                ) => {
                  validator.checkOptions.validate(requestOptions);
                }}
              />
            </BlueSectionPaper>
          </Box>

          {ownerInfoViaScraping ? (
            <Box
              sx={{
                mr: 2,
                my: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <MuiCheckbox
                    sx={{ padding: "0px 4px" }}
                    checked={withOwnerAnalyze}
                    disabled={!requestOptions.syoyusya.checked}
                    onChange={(e) => {
                      setWithOwnerAnalyze(e.target.checked);
                    }}
                  />
                }
                label="所有者事項証明書の取得と同時に内容を解析する"
              />
            </Box>
          ) : null}
          <Box
            sx={{
              mr: 2,
              mt: 2,
              mb: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              label="取得する"
              onClick={() => {
                void (async () => {
                  // すべての要素に対してバリデーションを実行する
                  const results = [
                    validator.pref.validate(prefecture),
                    validator.city.validate(city),
                    validator.realEstateType.validate(realEstateTypeEng),
                    validator.locationWithoutCity.validate(locationWithoutCity),
                    validator.chibanKaokuNumber.validate(chibanKaokuNumber),
                    validator.checkOptions.validate(requestOptions),
                  ];

                  // ここでreact hook formのバリデーションを実行する
                  await reactHookForm.handleSubmit((_data, _event) => {
                    // resultsの全ての値がtrueならvalidation通過とみなす
                    if (results.every((value) => value)) {
                      setOpenModalFlag(true);
                    }
                    /*
                    reactHookForm.handleSubmit()は関数を返す関数なので
                    (reactHookForm.handleSubmit(callback))()とする必要がある
                     */
                  })();
                })();
              }}
              variant={ButtonVariantOption.Contained}
              size={SizingWrapperStyle.SMALL}
            />
          </Box>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>

      {/* 確認のモーダル画面 */}
      <Dialog
        open={openModalFlag}
        onClose={() => {
          setOpenModalFlag(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            【課金が発生します】謄本/図面を取得します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            timeout={30000}
            variant={ButtonVariantOption.Contained}
            onClick={(_event, cancelLoading) => {
              handleSubmit(
                prefecture,
                city,
                locationWithoutCity,
                "",
                chibanKaokuNumber,
                pastDays,
                realEstateTypeEng,
                requestOptions,
                withOwnerAnalyze,
                reactHookForm.getValues("identifyName"),
                cancelLoading
              );
            }}
            label={"取得する"}
          />
          <Button
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              setOpenModalFlag(false);
            }}
            label={"キャンセル"}
          />
        </DialogActions>
      </Dialog>
    </PagePaper>
  );
};
