import React from "react";
import { ChibanViewer } from "./ChibanViewer";
import { Forbidden } from "../errors/Forbidden";
import { useMapServicePermission } from "@/hooks/useMapServicePermission";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const ChibanViewerAccessControl: React.FC = () => {
  const [hasMapViewingPermission, loading] = useMapServicePermission();
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // 閲覧権限がない場合は403エラーを表示
  if (!hasMapViewingPermission) {
    return <Forbidden />;
  }

  return (
    <>
      <ChibanViewer />
    </>
  );
};
