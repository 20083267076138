import { HttpStatusCode, type AxiosInstance } from "axios";
import { type DataGridRow, type DownloadCondition } from "../types";
import fileDownload from "js-file-download";
import { PictureTypeLabels, PictureTypeValues } from "../types/enums";

export const downloadAPI = async (
  apiClient: AxiosInstance,
  downloadCondition: DownloadCondition,
  selectRow: DataGridRow
): Promise<void> => {
  const params = new URLSearchParams(
    downloadCondition.ids
      .map((id) => ["ids", id])
      .concat([["is_reused", selectRow.isReused ? "1" : "0"]])
      .concat([
        ["is_stored", selectRow.lastUserAcquisitionDate != null ? "1" : "0"],
      ])
  );
  const response = await apiClient.get(
    "/picture-request/stored-pictures/download?" + params.toString(),
    { responseType: "arraybuffer" }
  );
  if (response.status === HttpStatusCode.Ok) {
    // ファイル名を作成。
    const pictureType =
      selectRow.pictureType === PictureTypeValues.SHOYUSHAJIKO
        ? PictureTypeLabels.SHOYUSHAJIKO
        : PictureTypeLabels.ZENBUJIKO;
    const fallBackName: string =
      selectRow.location + "_" + pictureType + ".zip";

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    fileDownload(response.data, fallBackName);
  }
};
