import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { Button, ButtonVariantOption, SubmitButton } from "@/components/Button";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { FeedGrid } from "@/features/acquireMultipleBooks/components/FeedGrid";
import React, { useState } from "react";
import { type AcquireMultipleBookHandoffData } from "@/types/acquirebook";
import { type BookRequestCheckboxStatuses } from "@/features/acquireMultipleBooks/types";
import { AlertBar } from "@/features/acquireMultipleBooks/components/AlertBar";
import { useFeatureFlags } from "@/configs/featureFlag";
import {
  DISTINCT_MESSAGE,
  makeOptionsInitialState,
  distinct,
} from "@/features/acquireMultipleBooks/presenter";
import Grid from "@mui/material/Grid";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

interface IAcquireMultipleBooksModalProps {
  handleSubmit: (
    rows: AcquireMultipleBookHandoffData[],
    checkBoxOptions: BookRequestCheckboxStatuses[],
    pastDays: number | "",
    withOwnerAnalyze: boolean,
    identificationName: string,
    cancelLoading: () => void
  ) => void;
  pastDays: number | "";
  setPastDays: React.Dispatch<React.SetStateAction<number | "">>;
  rows: AcquireMultipleBookHandoffData[];
}

export const AcquireMultipleBooksModal: React.FC<
  IAcquireMultipleBooksModalProps
> = ({ handleSubmit, pastDays, setPastDays, rows }) => {
  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = React.useState(false);

  const [withOwnerAnalyze, setWithOwnerAnalyze] = React.useState(false);

  const isSmallHeightScreen = useMediaQuery("(max-height:900px)");

  // 重複を取り除く
  const distinctedRows = distinct(rows);

  // 重複があった場合に表示するメッセージを設定する
  const [message, setMessage] = useState(
    distinctedRows.length !== rows.length ? DISTINCT_MESSAGE : ""
  );

  // チェックボックスの状態を管理するstateを生成する
  const [checkBoxOptions, setCheckBoxOptions] = useState(
    makeOptionsInitialState(distinctedRows)
  );

  const isOwnerInfoChecked = (
    checkBoxOptions: BookRequestCheckboxStatuses[]
  ): boolean => {
    for (const option of checkBoxOptions) {
      if (option.ownerInfo.checked) {
        return true;
      }
    }
    return false;
  };

  // 経過日数の入力値をチェックする
  const checkPastDays = (value: string): void => {
    if (value === "" || !isNaN(Number(value))) {
      setPastDays(value === "" ? "" : Number(value));
    }
  };

  const { ownerInfoViaScraping } = useFeatureFlags();

  // Zodスキーマ定義
  const FormDataSchema = z.object({
    identifyName: z.string().min(1, "識別名は必須です"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type FormData = z.infer<typeof FormDataSchema>;

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const reactHookForm = useForm<FormData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
    },
    resolver: zodResolver(FormDataSchema),
  });

  return (
    <PagePaper>
      {message !== "" ? (
        <AlertBar
          message={message}
          onClose={() => {
            setMessage("");
          }}
        />
      ) : (
        ""
      )}
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ ml: 0, color: "red", flexShrink: 0 }}>
              <ul>
                <li>最新の不動産登記/図面を取得いたします</li>
                <li>取得済みの場合は、課金が発生いたしません</li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md mb={1}>
            <Grid container spacing={1} justifyContent={"center"}>
              <Grid item xs={6}>
                <TextField
                  {...reactHookForm.register("identifyName")}
                  error={Boolean(reactHookForm.formState.errors.identifyName)}
                  helperText={
                    reactHookForm.formState.errors.identifyName?.message
                  }
                  label="識別名"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-pastdays">
                    前回取得日からの経過日数を指定する
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-pastdays"
                    type={"text"}
                    value={pastDays}
                    fullWidth={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      checkPastDays(event.target.value);
                    }}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        日
                        <Tooltip
                          title="取得済の場合でも経過日数を過ぎたものは再取得いたします"
                          placement="bottom"
                        >
                          <HelpIcon />
                        </Tooltip>
                      </InputAdornment>
                    }
                    label="前回取得日からの経過日数を指定する"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography fontWeight={"bold"} align={"right"}>
              列名をダブルクリックすると全選択する
            </Typography>
          </Grid>
        </Grid>
        <SectionPaper>
          <Box sx={{ height: isSmallHeightScreen ? 400 : 600, width: "100%" }}>
            <FeedGrid
              rows={distinctedRows}
              checkBoxOptions={checkBoxOptions}
              setCheckBoxOptions={setCheckBoxOptions}
              autoHeight={false}
            />
          </Box>
          {ownerInfoViaScraping ? (
            <Box
              sx={{
                mt: 6,
                mb: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <MuiCheckbox
                    sx={{ padding: "0px 4px" }}
                    checked={withOwnerAnalyze}
                    disabled={!isOwnerInfoChecked(checkBoxOptions)}
                    onChange={(e) => {
                      setWithOwnerAnalyze(e.target.checked);
                    }}
                  />
                }
                label="所有者事項証明書の取得と同時に内容を解析する"
              />
            </Box>
          ) : null}
          <Box
            sx={{
              mt: 1,
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant={ButtonVariantOption.Contained}
              onClick={() => {
                void (async () => {
                  // 現在がチェックかつ信託か共担のどちらかがチェックされていなかった場合、メッセージを表示する
                  const isInvalidOption = checkBoxOptions.some((value) => {
                    if (
                      value.genzai.checked &&
                      !(value.sintaku.checked || value.kyoutan.checked)
                    ) {
                      alert(
                        "現在事項を取得する場合には共担か信託を選択してください"
                      );
                      return true;
                    }
                    return false;
                  });
                  if (isInvalidOption) return;
                  // 一つもチェックがない場合、メッセージを表示する
                  const result = checkBoxOptions.some((value) => {
                    return (
                      Object.keys(value) as Array<
                        keyof BookRequestCheckboxStatuses
                      >
                    ).some((key) => value[key].checked);
                  });
                  if (!result) {
                    alert("取得したい謄本種別を最低一つはチェックしてください");
                    return;
                  }
                  // 一つでもチェックがあれば通す。チェックされていない行は取得登録されない
                  await reactHookForm.handleSubmit((_data, _event) => {
                    setOpenModalFlag(true);
                  })();
                })();
              }}
              label={"一括取得する"}
            />
          </Box>
        </SectionPaper>
        {/* 確認のモーダル画面 */}

        <Dialog
          open={openModalFlag}
          onClose={() => {
            setOpenModalFlag(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              【課金が発生します】謄本/図面を取得します。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              variant={ButtonVariantOption.Contained}
              onClick={(_event, cancelLoading) => {
                handleSubmit(
                  distinctedRows,
                  checkBoxOptions,
                  pastDays,
                  withOwnerAnalyze,
                  reactHookForm.getValues("identifyName"),
                  cancelLoading
                );
              }}
              label={"取得する"}
              timeout={30000}
            />
            <Button
              variant={ButtonVariantOption.Outlined}
              onClick={() => {
                setOpenModalFlag(false);
              }}
              label={"キャンセル"}
            />
          </DialogActions>
        </Dialog>
      </form>
    </PagePaper>
  );
};
