import { useFeatureFlags } from "@/configs/featureFlag";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { StoredPictures } from "@/features/mypage/routes/StoredPictures";
import { MyPage } from "@/features/mypage/routes/Mypage";

export const MypageRoutes: React.FC = () => {
  const { realEstateRegistrationAggregationOfNames, mypageTop } =
    useFeatureFlags();

  return (
    <Routes>
      {mypageTop ? <Route path="" element={<MyPage />} /> : null}
      {realEstateRegistrationAggregationOfNames ? (
        <Route path="storedpictures" element={<StoredPictures />} />
      ) : null}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};
