import { type AxiosInstance, HttpStatusCode, isAxiosError } from "axios";
import { type SearchCondition, type DataGridRow } from "../types";
import { PictureTypeValues } from "../types/enums";
import * as Sentry from "@sentry/react";

export const getStoredPicturesListAPI = async (
  apiClient: AxiosInstance,
  searchCondition: SearchCondition
): Promise<DataGridRow[]> => {
  // 事項種別の項目を作成。
  const pictureTypes: string[] = [];
  if (searchCondition.pictureTypeShoyushajiko)
    pictureTypes.push(PictureTypeValues.SHOYUSHAJIKO);
  if (searchCondition.pictureTypeZenbujiko)
    pictureTypes.push(PictureTypeValues.ZENBUJIKO);

  const params = new URLSearchParams(
    searchCondition.prefectures
      .map((prefecture) => ["prefectures", prefecture])
      .concat([
        [
          "location",
          searchCondition.location != null ? searchCondition.location : "",
        ],
      ])
      .concat(pictureTypes.map((pictureType) => ["picture_types", pictureType]))
      .concat([
        [
          "owner_name",
          searchCondition.ownerName != null ? searchCondition.ownerName : "",
        ],
      ])
      .concat([
        [
          "owner_address",
          searchCondition.ownerAddress != null
            ? searchCondition.ownerAddress
            : "",
        ],
      ])
      .concat([
        ["is_exist_mochibun", searchCondition.isExistMochibun ? "1" : "0"],
      ])
      .concat([
        [
          "is_not_exist_mochibun",
          searchCondition.isNotExistMochibun ? "1" : "0",
        ],
      ])
  );

  const response = await apiClient
    .get<DataGridRow[]>("/picture-request/stored-pictures?" + params.toString())
    .catch((error): void => {
      if (
        isAxiosError(error) &&
        error.response?.status !== HttpStatusCode.Unauthorized
      ) {
        Sentry.captureException(error);
      }
      return undefined;
    });

  if (response) {
    return response.status === HttpStatusCode.Ok ? response.data : [];
  }
  return [];
};
