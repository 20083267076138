import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { PageTitle } from "@/components/Title";
import { Label } from "@/components/Label";
import React, { useEffect, useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, ButtonVariantOption } from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { Add } from "@mui/icons-material";
import { SearchMailTemplateModal } from "../components/MailScheduleCreator/SearchMailTemplateModal";
import { useMailTemplateDetailRequest } from "../hooks/useMailTemplateDetailRequest";
import { SearchCustomerModal } from "../components/MailScheduleCreator/SearchCustomerModal";
import type {
  CustomerStatusDataGridRow,
  DestinationConditions,
} from "@/features/customerManagement/types";
import { CustomModal } from "@/components/Modal";
import { CustomerListFeedGrid } from "../components/MailScheduleCreator/CustomerListFeedGrid";
import { MailStatus } from "../components/MailStatus";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateMailAPI } from "../api/useUpdateMailAPI";
import { MailStatusMapping } from "../api/types";
import { useMailDetailAPI } from "../hooks/useMailDetailAPI";
import MailPageTemplate from "../components/MailPageTemplate";
import useScheduledSendAt from "../hooks/useScheduledSendAt";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch, Controller } from "react-hook-form";
import { useMailSendToCountAPI } from "../hooks/useMailSendToCountAPI";
import { FilterCustomerModal } from "../components/MailScheduleCreator/FilterCustomerModal";
import { DestinationTypeLabels, DestinationTypeValues } from "../types/enums";

export const destinationConditionSchema = z.object({
  email: z.string(),
  legalAffairsBureauRequestDate: z.date().nullable(),
  mochibun: z.string(),
  originalShozai: z.string(),
  postcode: z.string(),
  receptionReason: z.array(z.string()),
  // 不動産種別
  realEstateTypeTochi: z.boolean(),
  realEstateTypeKutate: z.boolean(),
  realEstateTypeTatemono: z.boolean(),
  // ステータス
  statusNoApproach: z.boolean(),
  statusApproach: z.boolean(),
  statusRejected: z.boolean(),
  statusNoResponse: z.boolean(),
  statusComplaint: z.boolean(),
  statusSent: z.boolean(),
  statusReaction: z.boolean(),
  // 含む・含まない
  includeEmail: z.boolean(),
  includeLegalAffairsBureauRequestDate: z.boolean(),
  includeMochibun: z.boolean(),
  includeOriginalShozai: z.boolean(),
  includePostcode: z.boolean(),
  includeReceptionReason: z.boolean(),
  includeRealEstateType: z.boolean(),
  includeStatus: z.boolean(),
});

const updateMailFormSchema = z
  .object({
    status: z.enum(["saved", "scheduled", "schedule_cancelled"]),
    title: z.string().min(1, "タイトルは必須です"),
    body: z.string().min(1, "テンプレートを選択して下さい"),
    destinationType: z.enum([
      DestinationTypeValues.ALL,
      DestinationTypeValues.CONDITION,
      DestinationTypeValues.CUSTOMER_SELECT,
    ]),
    destinationConditions: destinationConditionSchema.nullable(),
    sendTo: z.array(z.number()).nullable(),
    scheduledSendAt: z.date().nullable(),
  })
  .transform((data) => {
    let dateString = null;
    let timeString = null;
    if (data.scheduledSendAt) {
      dateString = data.scheduledSendAt.toISOString().split("T")[0]; // YYYY-MM-DD
      timeString = `${data.scheduledSendAt.getHours()}:${data.scheduledSendAt.getMinutes()}`; // HH:MM
    }
    return {
      ...data,
      scheduledSendDate: dateString,
      scheduledSendTime: timeString,
    };
  });

export type UpdateMailFormSchema = z.infer<typeof updateMailFormSchema>;

export const MailEdit: React.FC = () => {
  const navigate = useNavigate();
  const scheduledSendAt = useScheduledSendAt();
  const { id } = useParams();
  const mailId = parseInt(id as string);
  const { data } = useMailDetailAPI(mailId);
  // 顧客リストから選択された顧客リスト
  const [sendRows, setSendRows] = useState<CustomerStatusDataGridRow[]>([]);
  // モーダルの表示状態を画面全体で管理
  const [isModalOpen, setIsModalOpen] = useState({
    template: false,
    customer: false,
    condition: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<UpdateMailFormSchema>({
    mode: "all",
    defaultValues: {
      status: "saved",
      title: "",
      body: "",
      destinationType: DestinationTypeValues.ALL,
      destinationConditions: null,
      sendTo: null,
      scheduledSendAt,
      scheduledSendDate: null,
      scheduledSendTime: null,
    },
    resolver: zodResolver(updateMailFormSchema),
  });

  const updateMailForm = useWatch({ control });

  // 送信先(すべての顧客)件数を取得
  const sendToAll = useMailSendToCountAPI({
    destinationType: DestinationTypeValues.ALL,
    destinationConditions: null,
  });
  // 条件を絞り込んで送付のデフォルト値
  const defaultDestinationConditions: DestinationConditions = {
    email: "",
    legalAffairsBureauRequestDate: null,
    mochibun: "",
    originalShozai: "",
    postcode: "",
    receptionReason: [],
    // 不動産種別
    realEstateTypeTochi: true,
    realEstateTypeKutate: true,
    realEstateTypeTatemono: true,
    // ステータス
    statusNoApproach: true,
    statusApproach: true,
    statusRejected: true,
    statusNoResponse: true,
    statusComplaint: true,
    statusSent: true,
    statusReaction: true,
    includeEmail: true,
    includeLegalAffairsBureauRequestDate: true,
    includeMochibun: true,
    includeOriginalShozai: true,
    includePostcode: true,
    includeReceptionReason: true,
    includeRealEstateType: true,
    includeStatus: true,
  };
  // 条件を絞り込んで送付の設定
  const [destinationConditions, setDestinationConditions] =
    useState<DestinationConditions>(defaultDestinationConditions);

  // テンプレートの選択
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null
  );

  // メールテンプレートの取得
  const { data: mailTemplate } =
    useMailTemplateDetailRequest(selectedTemplateId);

  const { updateMail } = useUpdateMailAPI(mailId);

  // テンプレートの選択時のイベント
  const handleTemplateSelect = (id: number): void => {
    setSelectedTemplateId(id);
    setIsModalOpen((prev) => ({ ...prev, template: false }));
  };

  // 顧客追加ボタンクリック時のイベント
  const handleAddCustomer = (customers: CustomerStatusDataGridRow[]): void => {
    setIsModalOpen((prev) => ({ ...prev, customer: false }));
    setSendRows(customers);
    setValue(
      "sendTo",
      customers.map((customer) => customer.realEstateOwnerCustomerManagementId)
    );
  };

  // 条件を絞り込むボタンクリック時のイベント
  const handleFilter = (
    customers: CustomerStatusDataGridRow[],
    destinationConditions: DestinationConditions
  ): void => {
    setIsModalOpen((prev) => ({ ...prev, condition: false }));
    setSendRows(customers);
    setValue("destinationConditions", destinationConditions);
    setValue(
      "sendTo",
      customers.map((customer) =>
        Number(customer.realEstateOwnerCustomerManagementId)
      )
    );
    setDestinationConditions(destinationConditions);
  };

  // 保存、取り消し、予約送信ボタンクリック時のイベント
  const onSubmit = (data: UpdateMailFormSchema): void => {
    updateMail(data, (id: number) => {
      navigate(`/emailManagement/mail/detail/${id}/`);
    });
  };

  // モーダルの表示状態を切り替える
  const toggleModal = (
    type: "template" | "customer" | "condition",
    isOpen: boolean
  ): void => {
    setIsModalOpen((prev) => ({ ...prev, [type]: isOpen }));
  };

  useEffect(() => {
    // メールテンプレートの本文が更新された場合のみ、updateMailRequest の body を更新
    if (mailTemplate) {
      setValue("body", mailTemplate.body);
    }
  }, [mailTemplate]);

  useEffect(() => {
    if (data) {
      reset({
        status: data?.status as "saved" | "scheduled" | "schedule_cancelled",
        title: data?.title ?? "",
        body: data?.body ?? "",
        destinationType: data?.destinationType ?? DestinationTypeValues.ALL,
        destinationConditions: data?.destinationConditions,
        sendTo:
          data?.clients?.map((client) => client.customerManagementId) ?? null,
        scheduledSendAt: null,
      });
      if (data.scheduledSendAt) {
        const scheduledSendAt = new Date(data.scheduledSendAt);
        setValue("scheduledSendAt", scheduledSendAt);
      }
      if (
        data.destinationType === DestinationTypeValues.CONDITION ||
        data.destinationType === DestinationTypeValues.CUSTOMER_SELECT
      ) {
        setSendRows(
          data.clients?.map((client) => {
            return {
              id: client.customerManagementId,
              realEstateOwnerCustomerManagementId: client.customerManagementId,
              status: client.status ?? "", // Ensure status is always a string
              ownerName: client.ownerName ?? "",
              ownerAddress: client.ownerAddress ?? "",
              postcode: client.postcode ?? "",
              email: client.email ?? "",
              tel: client.tel ?? "",
              originalShozai: client.originalShozai ?? "",
              realEstateType: client.realEstateType ?? "",
              legalAffairsBureauRequestDate:
                client.legalAffairsBureauRequestDate,
              mochibunStr: client.mochibunStr ?? "",
              receptionReason: client.receptionReason ?? "",
              responsiblePersonName: client.responsiblePersonName ?? "",
              isOutOfTarget: client.isOutOfTarget ?? false,
            };
          }) ?? []
        );
        setDestinationConditions(
          data?.destinationConditions != null
            ? data?.destinationConditions
            : defaultDestinationConditions
        );
      }
    }
  }, [data]);

  return (
    <MailPageTemplate>
      <form
        onSubmit={
          handleSubmit(onSubmit) as React.FormEventHandler<HTMLFormElement>
        }
      >
        {/* ページタイトル */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <PageTitle>予約送信メール更新</PageTitle>
          {data?.status && (
            <MailStatus
              status={
                MailStatusMapping[data.status] as
                  | "保存"
                  | "保存済み"
                  | "未保存"
                  | "送信待ち"
                  | "送信取消済"
                  | "送信済み"
              }
            />
          )}
        </Box>

        <Box
          sx={{
            display: "grid",
            width: "60%",
            margin: "56px auto",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Label>件名</Label>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="standard"
                  error={!!errors.title}
                  helperText={errors.title ? errors?.title?.message : ""}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Label>本文</Label>
              <Button
                label="テンプレート選択"
                onClick={() => {
                  toggleModal("template", true);
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.INHERIT}
                startIcon={<Add />}
              />
            </Box>
            <Controller
              name="body"
              control={control}
              render={({ field }) => (
                <TextareaAutosize
                  {...field}
                  placeholder="テンプレートを選択してください。"
                  minRows={4}
                  disabled
                />
              )}
            />
            {errors.body && (
              <Typography color="error" variant="caption">
                {errors.body.message}
              </Typography>
            )}
          </Box>

          <Box>
            <FormControl>
              <Label>送信先の選択</Label>
              <Controller
                name="destinationType"
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value={DestinationTypeValues.ALL}
                      control={<Radio />}
                      label={DestinationTypeLabels.ALL}
                    />
                    <FormControlLabel
                      value={DestinationTypeValues.CONDITION}
                      control={<Radio />}
                      label={DestinationTypeLabels.CONDITION}
                    />
                    <FormControlLabel
                      value={DestinationTypeValues.CUSTOMER_SELECT}
                      control={<Radio />}
                      label={DestinationTypeLabels.CUSTOMER_SELECT}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

            {/* すべての顧客へ送付 */}
            {updateMailForm.destinationType === DestinationTypeValues.ALL && (
              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography color="rgba(0, 0, 0, 0.87)" fontSize="small">
                  {sendToAll.data?.count}件の宛先に送信予定です
                </Typography>
              </Box>
            )}

            {/* 条件を絞り込むボタン */}
            {updateMailForm.destinationType ===
              DestinationTypeValues.CONDITION && (
              <Box sx={{ mt: 3 }}>
                <Stack my={2} direction="row" justifyContent="end" spacing={1}>
                  <Button
                    label="条件を絞り込む"
                    onClick={() => {
                      toggleModal("condition", true);
                    }}
                    variant={ButtonVariantOption.Outlined}
                    size={SizingWrapperStyle.INHERIT}
                    startIcon={<Add />}
                  />
                </Stack>
                <CustomerListFeedGrid rows={sendRows} />
              </Box>
            )}

            {/* 顧客リストから選択 */}
            {updateMailForm.destinationType ===
              DestinationTypeValues.CUSTOMER_SELECT && (
              <Box sx={{ mt: 3 }}>
                <Stack my={2} direction="row" justifyContent="end" spacing={1}>
                  <Button
                    label="顧客追加"
                    onClick={() => {
                      toggleModal("customer", true);
                    }}
                    variant={ButtonVariantOption.Outlined}
                    size={SizingWrapperStyle.INHERIT}
                    startIcon={<Add />}
                  />
                </Stack>
                <CustomerListFeedGrid rows={sendRows} />
              </Box>
            )}
          </Box>

          <Box>
            <Label>送信日時</Label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="scheduledSendAt"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    value={field.value}
                    onChange={field.onChange}
                    minDateTime={scheduledSendAt}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // テキストフィールドを読み取り専用にして、カレンダーからのみ日時を選択できるようにする
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true,
                          inputProps: {
                            ...params.inputProps,
                            readOnly: true,
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              label="保存"
              type="submit"
              onClick={() => {
                setValue("status", "saved");
              }}
              disabled={
                updateMailForm.title === "" || updateMailForm.body === ""
              }
              variant={ButtonVariantOption.Outlined}
              size={SizingWrapperStyle.SMALL}
            />
            {data?.status === "scheduled" && (
              <Button
                label="取り消し"
                type="submit"
                disabled={
                  updateMailForm.title === "" || updateMailForm.body === ""
                }
                onClick={() => {
                  setValue("status", "schedule_cancelled");
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
            )}
            {(data?.status === "saved" ||
              data?.status === "schedule_cancelled") && (
              <Button
                label="予約送信"
                type="submit"
                onClick={() => {
                  setValue("status", "scheduled");
                }}
                disabled={
                  updateMailForm.title === "" ||
                  updateMailForm.body === "" ||
                  !updateMailForm.scheduledSendAt
                }
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
            )}
          </Box>
        </Box>
      </form>

      {/* テンプレート選択モーダル */}
      <CustomModal
        isOpen={isModalOpen.template}
        handleClose={() => {
          toggleModal("template", false);
        }}
        ariaLabelledby="template-selection-modal"
        ariaDescribedby="template-selection-modal-description"
      >
        <SearchMailTemplateModal onSelectTemplate={handleTemplateSelect} />
      </CustomModal>

      {/* 顧客追加モーダル */}
      <CustomModal
        isOpen={isModalOpen.customer}
        handleClose={() => {
          toggleModal("customer", false);
        }}
        ariaLabelledby="customer-selection-modal"
        ariaDescribedby="customer-selection-modal-description"
      >
        <SearchCustomerModal onAddCustomer={handleAddCustomer} />
      </CustomModal>

      {/* 条件を絞り込むモーダル */}
      <CustomModal
        isOpen={isModalOpen.condition}
        handleClose={() => {
          toggleModal("condition", false);
        }}
        ariaLabelledby="condition-selection-modal"
        ariaDescribedby="condition-selection-modal-description"
        height={"90%"}
        width={"90%"}
      >
        <FilterCustomerModal
          handleFilter={handleFilter}
          defaultDestinationConditions={destinationConditions}
        />
      </CustomModal>
    </MailPageTemplate>
  );
};
