import { type ReactElement } from "react";
import Typography from "@mui/material/Typography";
import { TextField } from "@/components/TextField";
import { Button, ButtonVariantOption } from "@/components/Button";
import { Box } from "@mui/material";
import { SizingWrapperStyle } from "@/components/Wrapper";

interface Props {
  label: string;
  handleApiRequest: () => Promise<void>;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

export const SearchBox = ({
  label,
  handleApiRequest,
  searchTerm,
  setSearchTerm,
}: Props): ReactElement<Props> => {
  return (
    <div>
      <Typography>{label}</Typography>
      <Box>
        <Box sx={{ my: 1 }}>
          <TextField
            id="chiban"
            label={label}
            onChange={setSearchTerm}
            size={SizingWrapperStyle.INHERIT}
            value={searchTerm}
            autoFocus={false}
          />
        </Box>
        <Box sx={{ my: 1 }}>
          <Button
            label={"検索"}
            onClick={() => {
              void handleApiRequest();
            }}
            size={SizingWrapperStyle.INHERIT}
            variant={ButtonVariantOption.Contained}
          />
        </Box>
      </Box>
    </div>
  );
};
