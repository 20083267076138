import {
  Box,
  Button as MuiButton,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { PagePaper, SectionPaper } from "../../components/Paper";
import { PageTitle } from "../../components/Title";
import { FeedGrid } from "./components/FeedGrid";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React from "react";
import { useCommercialBookStatus } from "./hooks/useCommercialBookStatus";
import { useParams } from "react-router-dom";
// API実装後にjs-file-downloadに統一する。
// import { type CommercialBookStatus } from "../types";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";

export const CommercialBookListPresenter: React.FC = () => {
  const { id } = useParams();

  const { data, getStatusString } = useCommercialBookStatus(id);
  const acStatusMessage = getStatusString(data);
  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ImportContactsIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>商業謄本取得待ち</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={3} sx={{ ml: 0, color: "red" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ color: "#4A86DF", fontSize: "20px" }}
            >
              ステータス: {acStatusMessage}
            </Typography>
          </Grid>
        </Grid>
        <SectionPaper>
          <FeedGrid row={data?.list} />
          <Grid item xs my={5}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <MuiButton
                variant="contained"
                size="large"
                sx={{
                  display: "none", // 完成してないので非表示
                  backgroundColor: "#4A50DF",
                  color: "white",
                  "&:hover": { backgroundColor: "#3a40b0" },
                }}
                // TODO: 一括ダウンロード処理はAPI実装後に修正予定。仮実装のみ
                // onClick={() => {
                //   // ここでFeedGridのuriのリストを取得
                //   const uris = (data?.list as CommercialBookStatus[])
                //     .map((item: CommercialBookStatus) => item.uri)
                //     .filter((uri: string) => uri);
                //   if (!uris || uris.length === 0) {
                //   } else if (uris.length === 1) {
                //     // URIが1つだけの場合、そのURLからダウンロード
                //     window.location.href = uris[0];
                //   } else {
                //     // 複数のURIがある場合にZIPファイル作成
                //     const zip = new JSZip();
                //     uris.forEach((uri: string) => {
                //       zip.file(uri);
                //     });
                //     zip
                //       .generateAsync({ type: "blob" })
                //       .then(function (content) {
                //         saveAs(content, "example.zip");
                //       });
                //   }
                // }}
              >
                一括ダウンロード
              </MuiButton>
              <MuiButton
                sx={{ display: "none" }} // 完成してないので非表示
                variant="contained"
                size="large"
                onClick={() => {
                  console.log("再取得する");
                }}
              >
                再取得する
              </MuiButton>
            </Stack>
          </Grid>
        </SectionPaper>
      </form>
    </PagePaper>
  );
};
