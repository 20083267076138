import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { type GeoSpacePolygonItem, type MarkerState } from "../types";
import {
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, {
  LatLng,
  type Map,
  type Popup as LeafletPopup,
  type LeafletMouseEvent,
} from "leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { GeospaceChibanTileLayer } from "@/features/map/components/GeospaceChibanTileLayer";
import { Button, ButtonVariantOption } from "@/components/Button";
import { renderToString } from "react-dom/server";
import { Box } from "@mui/material";
import {
  AreaUsePurposeLayerControlWrapper,
  AREA_USE_PURPOSE_LAYER_NAME,
} from "./AreaUsePurposeLayerControlWrapper";

// ピンのデフォルト画像を設定する
const DefaultIcon = L.icon({
  iconUrl: icon,
  iconRetinaUrl: iconRetina,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

/**
 * 新しいMarkerStateと古いMarkerStateの差分を抽出する
 * MarkerStateの中の_idが一致するかどうかで差分検知をしている
 * @param newStates 新しいMarkerState配列
 * @param oldStates 更新前のMarkerState配列
 */
const extractNewMarkerStates = (
  newStates: MarkerState[],
  oldStates: MarkerState[]
): MarkerState[] => {
  const result: MarkerState[] = [];
  newStates.forEach((newItem) => {
    if (!oldStates.some((oldItem) => oldItem._id === newItem._id)) {
      result.push(newItem);
    }
  });
  // console.log("差分", result);
  return result;
};

/**
 * ポップアップ付きのピンを生成する
 * @param content Reactコンポーネント
 * @param latlng 表示する座標
 * 生成できない場合はnullを返す
 */
const makePopupMarker = ({ content, latlng }: MarkerState): null | L.Marker => {
  if (!latlng) return null;
  if (!content) return null;

  const p1 = L.popup({
    content: renderToString(content as React.ReactElement),
  });
  return new L.Marker(latlng).bindPopup(p1);
};

/**
 * ピンのリストを生成する
 * ピンを生成した時nullが返ってきた場合はリストから取り除く
 * @param states
 */
const makePopupMarkerListWithMarkerStates = (
  states: MarkerState[]
): L.Marker[] => {
  const markers =
    states.map((markerState) => {
      return makePopupMarker(markerState);
    }) ?? [];
  // nullになったピンは取り除く
  return markers?.filter((marker): marker is L.Marker => marker !== null);
};

interface Props {
  clickedAreaPolygonData: GeoSpacePolygonItem[] | undefined;
  polygonData: GeoSpacePolygonItem[][] | undefined;
  viewLatLng: LatLng;
  setClickedLatLng: Dispatch<SetStateAction<LatLng | null>>;
  markerState: MarkerState;
  receptionBookMarkerStates?: MarkerState[];
}

const INITIAL_ZOOM = 17;

export const LeafletMap: React.FC<Props> = ({
  clickedAreaPolygonData,
  polygonData,
  viewLatLng,
  setClickedLatLng,
  markerState,
  receptionBookMarkerStates,
}) => {
  const containerStyle = {
    height: "83vh",
    width: "100%",
    display: "inline-block",
  };

  const [map, setMap] = useState<Map | null>(null);
  const [popupRef, setPopupRef] = useState<LeafletPopup | null>(null);
  const [layerControl, setLayerControl] = useState<L.Control.Layers | null>(
    null
  );

  // 地図インポートで立てるピンをまとめて保持するLayerGroup
  const [layerGroup, setLayerGroup] = useState<L.LayerGroup | null>(null);

  // 地番地図のAPP IDを取得
  const mapServiceAppId = localStorage.getItem("mapServiceAppId");

  // 古いreceptionBookMarkerStatesを保存しておくRef
  // refなので値が更新されても再レンダリングはしない
  const oldReceptionBookMarkerStates = useRef<MarkerState[]>([]);

  // receptionBookMarkerStatesの差分を保存する配列
  let diffReceptionBookMarkerStates: MarkerState[] = [];

  // receptionBookMarkerStatesの差分を検知して抽出し、旧値をoldReceptionBookMarkerStatesに保存する
  if (receptionBookMarkerStates) {
    if (
      receptionBookMarkerStates.length !==
      oldReceptionBookMarkerStates.current.length
    ) {
      diffReceptionBookMarkerStates = extractNewMarkerStates(
        receptionBookMarkerStates,
        oldReceptionBookMarkerStates.current
      );
      oldReceptionBookMarkerStates.current = receptionBookMarkerStates;
    }
  }

  // ---- ここからデバッグ用コード ----
  const [debugVisible, setDebugVisible] = useState<boolean>(false);
  interface DebugViewProps {
    visible: boolean;
  }
  const DebugView: React.FC<DebugViewProps> = ({ visible }) => {
    if (visible) {
      return (
        <Box sx={{ my: 1 }}>
          <Button
            label={"地図を東京へ移動"}
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              map?.setView(
                new LatLng(35.695530421125156, 139.78227391979706),
                13
              );
            }}
          />
        </Box>
      );
    }
    return null;
  };

  Object.assign(window, {
    debug: () => {
      console.log("debug ui on");
      setDebugVisible(true);
    },
  });
  // ---- デバッグ用コードここまで ----

  // 受付帳のマーカー情報が渡されている時はピンを立てる処理を実行する
  if (diffReceptionBookMarkerStates.length > 0) {
    // 初回のピン立て、ピンをまとめるレイヤーグループを生成する
    if (layerGroup === null) {
      const markers = makePopupMarkerListWithMarkerStates(
        diffReceptionBookMarkerStates
      );
      const lg = L.layerGroup(markers);
      setLayerGroup(lg);
      // lgを右上のレイヤーメニューに追加
      layerControl?.addOverlay(lg, "受付帳情報ピンを表示");
      map?.addLayer(lg);
      map?.setView(markers[0].getLatLng());
      map?.setZoom(14);
    } else {
      // レイヤーグループが存在する場合はMarkerStateの差分を新規追加
      makePopupMarkerListWithMarkerStates(
        diffReceptionBookMarkerStates
      ).forEach((item) => {
        layerGroup.addLayer(item);
      });
      // console.log("表示中レイヤー", layerGroup.getLayers());
    }
  }

  // 地図クリック時に実行する
  map?.addEventListener("click", (event) => {
    // マーカー位置の設定
    markerState.latlng = event.latlng;
    // clickedLatLngを更新すると、親コンポーネント内で自動的にAPIが呼び出されてPopupのデータが更新される
    setClickedLatLng(event.latlng);
  });

  // 表示座標のStateが更新された時に実行する
  // 主にUI側から検索ボタンが押された時に実行される
  useEffect(() => {
    map?.setView(viewLatLng, 19);
  }, [viewLatLng]);

  // ポップアップ設定が変更された時はポップアップを表示する
  useEffect(() => {
    if (map && markerState.content) {
      popupRef?.openOn(map);
    }
  }, [markerState]);

  return (
    <>
      <DebugView visible={debugVisible} />
      <MapContainer
        ref={setMap}
        center={viewLatLng}
        zoom={INITIAL_ZOOM}
        scrollWheelZoom={true}
        style={containerStyle}
        maxZoom={19}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={19}
        />
        <LayersControl ref={setLayerControl}>
          <LayersControl.Overlay name="地番を表示する" checked={true}>
            {mapServiceAppId && (
              <GeospaceChibanTileLayer mapServiceAppId={mapServiceAppId} />
            )}
          </LayersControl.Overlay>
          <LayersControl.Overlay
            name={AREA_USE_PURPOSE_LAYER_NAME}
            checked={false}
          >
            <AreaUsePurposeLayerControlWrapper
              defaultVisible={false}
              initialZoomLevel={INITIAL_ZOOM}
            />
          </LayersControl.Overlay>
        </LayersControl>

        <Marker
          position={markerState.latlng ?? viewLatLng}
          eventHandlers={{
            click: (event: LeafletMouseEvent) => {
              if (!markerState.content) {
                (event.target as L.Marker).closePopup();
              }
            },
          }}
        >
          <Popup ref={setPopupRef}>{markerState.content}</Popup>
        </Marker>
        <Polygon positions={polygonData as LatLng[][]} />
        <Polygon
          positions={clickedAreaPolygonData as LatLng[]}
          pathOptions={{ color: "green", fill: false }}
        />
      </MapContainer>
    </>
  );
};
