import { useAcquireBookForm } from "./hooks/useAcquireBookForm";
import { AcquireMultipleBooksPresenter } from "./presenter";
import { useState } from "react";

export const AcquireMultipleBooksContainer: React.FC = () => {
  const { handleSubmit } = useAcquireBookForm();

  // 前回取得日からの経過日数テキストボックス
  const [pastDays, setPastDays] = useState<number | "">(0);

  return (
    <AcquireMultipleBooksPresenter
      handleSubmit={handleSubmit}
      pastDays={pastDays}
      setPastDays={setPastDays}
    />
  );
};
