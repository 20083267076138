import { type GridSortDirection, type GridSortModel } from "@mui/x-data-grid";
import { format, parse } from "date-fns";
import { ja } from "date-fns/locale";
import type React from "react";
import { useEffect } from "react";
import {
  type IRealEstateReceptionBookResponse,
  type IRealEstateReceptionBookDataGridRow,
  type IMultipleBookAcquireData,
} from "../types";
import { type IUseFeedParams, useFeed } from "./useFeed";
import { type GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import { type GridCallbackDetails } from "@mui/x-data-grid/models/api";
import {
  type AcquireMultipleBookHandoffData,
  type BookTypeJpn,
} from "../../../types/acquirebook";
import { convertBookTypeToEng } from "../../../utils/utilsAcquireBook";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import type { User } from "@/types/localstorage";

interface IUseFeedGridParams extends Omit<IUseFeedParams, "size"> {
  page: number;
  pageSize: number;
  setMultipleBookAcquireData: React.Dispatch<
    React.SetStateAction<IMultipleBookAcquireData>
  >;
}

interface IUseFeedGridReturnType {
  rows: IRealEstateReceptionBookDataGridRow[];
  allCount: number;
  loading: boolean;
  from: number;
  prev: () => void;
  next: () => void;
  sortByGridSortModel: (sortModel: GridSortModel | undefined) => void;
  selectionModelChange: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails
  ) => void;
}

type Dictionary = Record<string, string>;
const AreaUsePurposeDictionary: Dictionary = {
  第一種低層住居専用地域: "一低",
  第二種低層住居専用地域: "二低",
  田園住居地域: "田住",
  第一種中高層住居専用地域: "一中高",
  第二種中高層住居専用地域: "二中高",
  第一種住居地域: "一住",
  第二種住居地域: "二住",
  準住居地域: "準住",
  近隣商業地域: "近商",
  商業地域: "商業",
  準工業地域: "準工",
  工業地域: "工業",
  工業専用地域: "工専",
};

const FEED_FETCH_SIZE = 1000;

// useFeedの結果を、DataGridに表示するためのデータに変換して返すcustom hook
export const useGridFeed = (
  params: IUseFeedGridParams
): IUseFeedGridReturnType => {
  const { page, pageSize, setMultipleBookAcquireData, ...feedParams } = params;
  const { feed, allCount, from, prev, next, sort } = useFeed({
    ...feedParams,
    size: FEED_FETCH_SIZE,
  });
  const [user] = useLocalStorage<User | undefined>("user", undefined);

  // 現在取得済みの件数よりも、表示するページの件数が多い場合は、次のページを取得する
  useEffect(() => {
    const pageNumberForCalcDataset = page + 1;
    if (feed === null || feed === undefined) return;
    if (pageNumberForCalcDataset * pageSize <= feed.list.length) return;
    next();
  }, [page, pageSize]);

  // MUIのDataGridのソート条件を、APIのソート条件に変換する
  const sortByGridSortModel = (sortModel: GridSortModel | undefined): void => {
    sort(toSortCondition(sortModel));
  };

  // dataGridの行が選択された時に呼び出される処理
  const selectionModelChange = (
    selectionModel: GridSelectionModel,
    _details: GridCallbackDetails
  ): void => {
    if (feed) {
      // selectionModel: 選択された項目のIDがリストで入っている
      // feedからselectionModelと同じIDを持っている項目を一つ取り出し、AcquireBookCsvUploadResponse型で出力する
      const selectedItem = selectionModel.map(
        (rowid, index): AcquireMultipleBookHandoffData => {
          const row = feed.list.filter((value) => value.id === rowid)[0];

          return {
            id: index + 1,
            bookId: row.id,
            bookType: convertBookTypeToEng(
              row.realEstateTypeName as BookTypeJpn
            ),
            prefCode: row.prefecturesId.toString(),
            prefName: row.prefecturesName,
            locationFull: row.address,
            chibanKaokuNumber: row.chiban + row.kaokuNumber,
            kyoutan: false,
            sintaku: false,
            genzai: false,
            ownerInfo: false,
            electricDrawings: false,
            tisekiDrawings: false,
            tiekiDrawings: false,
            buildingDrawings: false,
          };
        }
      );

      if (selectionModel.length > 0) {
        const isFree = user?.isFree ?? false; // isFreeが読めない時は通常ユーザー扱いとする
        setMultipleBookAcquireData({
          buttonString: "選択した謄本を取得",
          buttonDisabled: isFree,
          tooltipMessage: isFree ? "有料プランでご利用いただける機能です" : "",
          selectedItem,
        });
      } else {
        setMultipleBookAcquireData({
          buttonString: "不動産登記/図面取得",
          buttonDisabled: false,
          tooltipMessage: "",
          selectedItem,
        });
      }
    }
  };

  return {
    rows: toGridRows(feed),
    allCount,
    loading: feed === undefined,
    from,
    prev,
    next,
    sortByGridSortModel,
    selectionModelChange,
  };
};

// Gridに表示するデータの型に変換する関数
const toGridRows = (
  data: IRealEstateReceptionBookResponse | undefined
): IRealEstateReceptionBookDataGridRow[] => {
  if (data === undefined) return [];
  return data.list.map((item) => {
    const receptionKindLabel = item.isNew ? "新" : "既";
    let areaUsePurpose = item.areaUsePurpose;
    if (areaUsePurpose) {
      const areaUsePurposeFull = areaUsePurpose;
      areaUsePurpose =
        AreaUsePurposeDictionary[areaUsePurposeFull] || areaUsePurposeFull;
    }
    const hasTrade: string = item.hasTrade ? "有" : "";

    let isTowerApartment: string | null = null;
    let isOneRoom: string | null = null;
    if (item.realEstateTypeName === "区分建物") {
      isTowerApartment = item.isTowerApartment ? "○" : "×";
      isOneRoom = item.isOneRoom ? "○" : "×";
    }

    return {
      id: item.id,
      hasTrade,
      chiban: item.chiban,
      kaokuNumber: item.kaokuNumber,
      realEstateBookType: item.realEstateBookTypeName,
      receptionReason: item.receptionReason,
      receptionReasonType: item.receptionReasonTypeName,
      realEstateType: item.realEstateTypeName,
      receptionKind: receptionKindLabel,
      prefectures: item.prefecturesName,
      prefecturesId: item.prefecturesId,
      city: item.cityName,
      address: item.address,
      outside: item.outside,
      legalAffairsBureauRequestDate: format(
        parse(item.legalAffairsBureauRequestDate, "yyyy-MM-dd", new Date()),
        "yyyy年MM月dd日",
        {
          locale: ja,
        }
      ),
      legalAffairsBureauReceptionNumber: item.legalAffairsBureauReceptionNumber,
      estimatedChiseki: item.estimatedChiseki,
      publishedPrice: item.publishedPrice,
      estimatedPrice: item.estimatedPrice,
      areaUsePurpose,
      buildingRate: item.buildingRate,
      volumeRate: item.volumeRate,
      isTowerApartment,
      isOneRoom,
      propertyName: item.propertyName,
      buildDate: item.buildDate,
      station: item.station,
      walk: item.walk,
    };
  });
};

/**
 * 引数の値が、キーとなるフィールド名かどうかを判定するType Guard関数
 */
const isConditionField = (
  field: string
): field is keyof Omit<IRealEstateReceptionBookDataGridRow, "id"> => {
  const keys: Array<keyof Omit<IRealEstateReceptionBookDataGridRow, "id">> = [
    "chiban",
    "kaokuNumber",
    "realEstateBookType",
    "receptionReason",
    "realEstateType",
    "receptionKind",
    "prefectures",
    "city",
    "address",
    "outside",
    "legalAffairsBureauRequestDate",
    "legalAffairsBureauReceptionNumber",
    "estimatedChiseki",
    "publishedPrice",
    "estimatedPrice",
    "areaUsePurpose",
    "buildingRate",
    "volumeRate",
    "isTowerApartment",
    "isOneRoom",
    "propertyName",
    "buildDate",
    "walk",
    "station",
  ];

  return keys.some((key) => key === field);
};

/**
 * 引数がソート順かどうかを判定するType Guard関数
 */
const isSortOrder = (order: GridSortDirection): order is "asc" | "desc" => {
  if (order === undefined || order === null) return false;
  return ["asc", "desc"].includes(order);
};

/**
 * MUI Data Gridのソート条件を、APIのソート条件に変換する関数
 */
const toSortCondition = (
  model: GridSortModel | undefined
): Parameters<ReturnType<typeof useFeed>["sort"]>[0] | undefined => {
  if (model === undefined || model.length === 0) return undefined;

  // 無料版のMUI Data Gridは複数列のソートに対応していないので、
  // 1列目のみをソート条件として扱う
  const { field, sort } = model[0];
  if (!isConditionField(field) || !isSortOrder(sort)) return undefined;
  return { sortBy: field, order: sort };
};
