import React, { useState, useCallback, useEffect } from "react";
import { StyledDataGrid } from "./DataGrid";
import {
  type GridColDef,
  type GridSelectionModel,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  Box,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PagePaper, SectionPaper } from "@/components/Paper";

import {
  type KaokuNumberSearchRequest,
  type KaokuNumberSearchChiban,
  type KaokuNumberSearchKaokuNumber,
  type PropertySelectionRow,
} from "../types";

import { Button, SubmitButton, ButtonVariantOption } from "@/components/Button";

import { createKaokuNumberSearchRequestAPI } from "../api/createKaokuNumberSearchRequestAPI";
import { getKaokuNumberSearchRequestAPI } from "../api/getKaokuNumberSearchRequestAPI";
import { useApiClient } from "@/hooks/useApiClient";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { type BookTypeEng } from "../../../types/acquirebook";
import { SizingWrapperStyle } from "@/components/Wrapper";
import CloseIcon from "@mui/icons-material/Close";
import { NYHalfWidthNumberToFillWidthNumber } from "@/utils/utils";
import { toast } from "react-toastify";

const MAX_REQUEST_COUNT = 50;

interface Props {
  chibanList: KaokuNumberSearchChiban[];
  setKaokuNumberSearchModalIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  appendPropertySelectionRows: (rows: PropertySelectionRow[]) => void;
}

interface ChibanRow extends KaokuNumberSearchChiban {
  id: number;
  selected: boolean;
}

interface KaokuNumberRow extends KaokuNumberSearchKaokuNumber {
  id: number;
  selected: boolean;
}

export const KaokuNumberSearchModal: React.FC<Props> = ({
  chibanList,
  setKaokuNumberSearchModalIsOpen,
  appendPropertySelectionRows,
}) => {
  const { apiClient } = useApiClient();
  const [kaokuNumberRows, setKaokuNumberRows] = useState<KaokuNumberRow[]>([]);
  const [loading, setLoading] = useState(false);

  const chibanRows = (): ChibanRow[] => {
    return chibanList.map((chiban, index) => {
      return { id: index, selected: true, ...chiban };
    });
  };

  const convertToKaokuNumberRows = (
    list: KaokuNumberSearchKaokuNumber[]
  ): KaokuNumberRow[] => {
    return list.map((kaokuNumber, index) => {
      return { id: index, selected: true, ...kaokuNumber };
    });
  };

  const [selectedChibanRowIds, setSelectedChibanRowIds] =
    useState<GridSelectionModel>(() =>
      chibanRows()
        .filter((row) => row.selected)
        .map((row) => row.id)
    );

  const handleChibanRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedChibanRowIds(newSelectionModel);
    },
    []
  );

  const [selectedKaokuNumberRowIds, setSelectedKaokuNumberRowIds] =
    useState<GridSelectionModel>([]);

  const handleKaokuNumberRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedKaokuNumberRowIds(newSelectionModel);
    },
    []
  );

  const [openSearchModalFlag, setOpenSearchModalFlag] = React.useState(false);
  const [openAddRowsModalFlag, setOpenAddRowsModalFlag] = React.useState(false);
  const [pollingRequestId, setPollingRequestId] = React.useState<number | null>(
    null
  );

  useEffect(() => {
    if (!pollingRequestId) {
      return;
    }

    // 3秒ごとにポーリング
    const intervalId = setInterval(() => {
      void fetchKaokuNumberSearchRequest();
    }, 3000);

    // 15分でタイムアウト
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setPollingRequestId(null);
    }, 1000 * 60 * 15);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [pollingRequestId]);

  const chibanGridColumns: GridColDef[] = [
    {
      field: "prefectureName",
      headerName: "都道府県",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "所在",
      width: 400,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chiban",
      headerName: "地番",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return NYHalfWidthNumberToFillWidthNumber(value as string);
      },
    },
  ];

  const kaokuNumberGridColumns: GridColDef[] = [
    {
      field: "chibanFull",
      headerName: "地番",
      width: 480,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return NYHalfWidthNumberToFillWidthNumber(value as string);
      },
    },
    {
      field: "kaokuNumber",
      headerName: "家屋番号",
      width: 240,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return NYHalfWidthNumberToFillWidthNumber(value as string);
      },
    },
  ];

  const handleClickSubmitSearchButton = (cancelLoading: () => void): void => {
    setLoading(true);

    // リクエスト作成APIを呼び出す
    const selectedChibanList: KaokuNumberSearchChiban[] = chibanRows()
      .filter((row) => {
        return selectedChibanRowIds.includes(row.id);
      })
      .map((row) => {
        return {
          prefectureName: row.prefectureName,
          location: row.location,
          chiban: row.chiban,
        };
      });

    (async () => {
      try {
        const response = await createKaokuNumberSearchRequestAPI(
          apiClient,
          selectedChibanList
        );

        if (response) {
          if (response.status === HttpStatusCode.Accepted && response.id) {
            setPollingRequestId(response.id);
            setOpenSearchModalFlag(false);
            return;
          } else if (response.status === HttpStatusCode.BadRequest) {
            setLoading(false);
            if (
              response.error &&
              response.error === "登記情報提供サービスの営業時間外です"
            ) {
              toast.error("登記情報提供サービスの営業時間外です");
            } else {
              toast.error(`件数は${MAX_REQUEST_COUNT}件以下にしてください。`);
            }
          }
        }
      } catch (error) {
        setLoading(false);
        if (
          isAxiosError(error) &&
          error.response?.status !== HttpStatusCode.Unauthorized
        ) {
          Sentry.captureException(error);
        }
      } finally {
        cancelLoading();
      }
    })();
  };

  const convertResultToKaokuNumberList = (
    response: KaokuNumberSearchRequest
  ): KaokuNumberSearchKaokuNumber[] => {
    const kaokuNumberList: KaokuNumberSearchKaokuNumber[] = [];
    if (response.responses) {
      response.responses.results.forEach((result) => {
        result.kaokuNumberList.forEach((kaokuNumber) => {
          kaokuNumberList.push({
            prefectureName: result.pref,
            location: result.shozai,
            chiban: result.chiban,
            kaokuNumber,
            chibanFull: `${result.pref}${result.shozai}${result.chiban}`,
          });
        });
      });
    }

    return kaokuNumberList;
  };

  const fetchKaokuNumberSearchRequest = async (): Promise<void> => {
    if (!pollingRequestId) {
      return;
    }

    const response = await getKaokuNumberSearchRequestAPI(
      apiClient,
      pollingRequestId
    );
    if (response) {
      if (response.status === "COMPLETED") {
        const rows = convertToKaokuNumberRows(
          convertResultToKaokuNumberList(response)
        );
        setKaokuNumberRows(rows);
        setSelectedKaokuNumberRowIds(
          rows.filter((row) => row.selected).map((row) => row.id)
        );

        setPollingRequestId(null);
        setLoading(false);

        if (rows.length === 0) {
          toast.info("検索結果は0件です");
        }
      }
    }
  };

  const handleClickSubmitAddRowsButton = (cancelLoading: () => void): void => {
    const rows = kaokuNumberRows
      .filter((row) => {
        return selectedKaokuNumberRowIds.includes(row.id);
      })
      .map((row, index) => {
        return {
          id: index,
          bookType: "BUILDING" as BookTypeEng,
          selected: true,
          prefName: row.prefectureName,
          location: row.location,
          chibanKaokuNumber: row.kaokuNumber,
          isFeedOrigin: false,
        };
      });

    appendPropertySelectionRows(rows);

    setOpenAddRowsModalFlag(false);
    setKaokuNumberSearchModalIsOpen(false);
  };

  const handleClickCloseModalButton = (): void => {
    if (loading) {
      const confirmClose = window.confirm(
        "検索中です。画面を閉じるとキャンセルされます。よろしいですか？"
      );
      if (!confirmClose) {
        return;
      }
    }
    setKaokuNumberSearchModalIsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          outline: "none",
        }}
      >
        <Button
          label="閉じる"
          variant={ButtonVariantOption.Outlined}
          onClick={handleClickCloseModalButton}
          size={SizingWrapperStyle.SMALL}
          startIcon={<CloseIcon />}
        ></Button>
      </Box>
      <PagePaper>
        <Box>
          <SectionPaper>
            <Typography sx={{ pl: 2, pt: 1, fontWeight: "bold" }}>
              地番
            </Typography>
            <Box marginY={1} marginX={2} sx={{ height: 200 }}>
              <StyledDataGrid
                rowHeight={36}
                headerHeight={40}
                pageSize={30}
                hideFooter
                disableColumnMenu
                disableSelectionOnClick={true}
                columns={chibanGridColumns}
                rows={chibanRows()}
                checkboxSelection
                selectionModel={selectedChibanRowIds}
                onSelectionModelChange={handleChibanRowSelectionChange}
              ></StyledDataGrid>
            </Box>
            <Stack
              direction="row"
              justifyContent="end"
              spacing={1}
              marginTop={1}
              marginBottom={2}
              marginRight={2}
            >
              <Button
                label="家屋番号検索"
                variant={ButtonVariantOption.Contained}
                onClick={() => {
                  if (selectedChibanRowIds.length > MAX_REQUEST_COUNT) {
                    toast.error(
                      `件数は${MAX_REQUEST_COUNT}件以下にしてください。`
                    );
                    return;
                  }
                  setOpenSearchModalFlag(true);
                }}
                disabled={loading}
              ></Button>
            </Stack>
          </SectionPaper>
        </Box>
        <Box marginTop={2}>
          <SectionPaper>
            <Typography sx={{ pl: 2, pt: 1, fontWeight: "bold" }}>
              家屋番号
            </Typography>
            <Box marginY={1} marginX={2} sx={{ height: 300 }}>
              <StyledDataGrid
                rowHeight={36}
                headerHeight={40}
                disableColumnMenu
                disableSelectionOnClick={true}
                columns={kaokuNumberGridColumns}
                rows={kaokuNumberRows}
                checkboxSelection
                selectionModel={selectedKaokuNumberRowIds}
                onSelectionModelChange={handleKaokuNumberRowSelectionChange}
                loading={loading}
              ></StyledDataGrid>
            </Box>
            <Stack
              direction="row"
              justifyContent="end"
              spacing={1}
              marginTop={1}
              marginBottom={2}
              marginRight={2}
            >
              <Button
                label="リストに追加"
                variant={ButtonVariantOption.Contained}
                onClick={() => {
                  setOpenAddRowsModalFlag(true);
                }}
                disabled={loading}
              ></Button>
            </Stack>
          </SectionPaper>
        </Box>
      </PagePaper>
      <Dialog
        open={openSearchModalFlag}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setOpenSearchModalFlag(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedChibanRowIds.length}件の地番を検索します。
          </DialogContentText>
          <DialogContentText>
            件数が多い場合、検索完了まで時間がかかります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            variant={ButtonVariantOption.Contained}
            onClick={(_event, cancelLoading) => {
              handleClickSubmitSearchButton(cancelLoading);
            }}
            label={"検索する"}
            timeout={30000}
          />
          <Button
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              setOpenSearchModalFlag(false);
            }}
            label={"キャンセル"}
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAddRowsModalFlag}
        onClose={() => {
          setOpenAddRowsModalFlag(false);
        }}
      >
        <DialogTitle>{"確認画面"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedKaokuNumberRowIds.length}件をリストに追加します。
          </DialogContentText>
          <DialogContentText>よろしいですか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            variant={ButtonVariantOption.Contained}
            onClick={(_event, cancelLoading) => {
              handleClickSubmitAddRowsButton(cancelLoading);
            }}
            label={"はい"}
            timeout={3000}
          />
          <Button
            variant={ButtonVariantOption.Outlined}
            onClick={() => {
              setOpenAddRowsModalFlag(false);
            }}
            label={"キャンセル"}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
