import { useApiClient } from "@/hooks/useApiClient";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { type User } from "@/types/localstorage";
import { HttpStatusCode, isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

interface UserMeResponse {
  id: string;
  firstName: string;
  lastName: string;
  isFree: boolean;
  isStaff: boolean;
  email: string;
}

interface UserMe {
  userMe: () => void;
}

export const useUserMe = (): UserMe => {
  const { apiClient } = useApiClient();
  const url = "/auth/users/me/";
  const [, setUser] = useLocalStorage<User | undefined>("user", undefined);
  const userMe = (): void => {
    apiClient
      .get<UserMeResponse>(url)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error): void => {
        if (
          isAxiosError(error) &&
          error.response?.status !== HttpStatusCode.Unauthorized
        ) {
          Sentry.captureException(error);
        }
        return undefined;
      });
  };

  return { userMe };
};
