import React from "react";
import { Box } from "@mui/material";

export const Forbidden: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <h1>403 - Forbidden</h1>
      <p>アクセスが拒否されました。</p>
    </Box>
  );
};

export default Forbidden;
