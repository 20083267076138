import { Avatar, Box, Container } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { TextField, TextFieldPassword } from "@/components/TextField";
import { SubmitButton, ButtonVariantOption } from "../../components/Button";
import { type ILoginPresenterProps } from "./types";
import React from "react";

const theme = createTheme();

const LoginPresenter: React.FC<ILoginPresenterProps> = (props) => {
  const { handleSubmit, setPassword, setEmail, password, email } = props;

  // ログインボタンをスクリプトからクリックするために使用するref
  const ref = React.useRef<HTMLButtonElement>(null);

  // テキストボックスの有効/無効を制御するstate
  const [loading, setLoading] = React.useState(false);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      ref.current?.click();
    }
  };

  // ログインUIのリセット
  const resetLoginUI = (cancelLoading: () => void) => {
    return () => {
      cancelLoading();
      setLoading(false);
    };
  };

  const handleClick = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    setLoading(true);
    void handleSubmit(resetLoginUI(cancelLoading));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* 鍵アイコン */}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>

          {/* メールアドレス */}
          <Box sx={{ my: 1 }}>
            <TextField
              id="email"
              label="メールアドレス"
              value={email}
              onChange={setEmail}
              onKeyUp={handleKeyUp}
              required={true}
              autoFocus={true}
              size={SizingWrapperStyle.LARGE}
              disabled={loading}
            />
          </Box>

          {/* パスワード */}
          <Box sx={{ my: 1 }}>
            <TextFieldPassword
              id="password"
              label="パスワード"
              value={password}
              onChange={setPassword}
              onKeyUp={handleKeyUp}
              size={SizingWrapperStyle.LARGE}
              disabled={loading}
            />
          </Box>

          {/* ログインボタン */}
          <Box sx={{ my: 1 }}>
            <SubmitButton
              label={"ログイン"}
              onClick={handleClick}
              variant={ButtonVariantOption.Contained}
              size={SizingWrapperStyle.LARGE}
              loadingIndicator={"ログイン中..."}
              timeout={30000}
              refToClickButton={ref}
              onTimeout={() => {
                setLoading(false);
              }}
            />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export { LoginPresenter };
