import { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import maplibregl, { type StyleSpecification } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@maplibre/maplibre-gl-leaflet";
import L from "leaflet";
import "./layer.css";

interface Props {
  visible: boolean;
  initialZoomLevel: number;
}

const typeTextTable: Record<string, string> = {
  "1": "第一種低層住居専用",
  "2": "第二種低層住居専用",
  "3": "第一種中高層住居専用",
  "4": "第二種中高層住居専用",
  "5": "第一種住居",
  "6": "第二種住居",
  "7": "準住居",
  "8": "近隣商業",
  "9": "商業",
  "10": "準工業",
  "11": "工業",
  "12": "工業専用",
};

const MIN_ZOOM = 16;
const MAX_ZOOM = 20;
const SOURCE_NAME = "areaUsePurpose";
const SOURCE_LAYER_NAME = "areausepurpose";

// MapLibreのスタイル定義
// https://maplibre.org/maplibre-style-spec/
const MAPLIBRE_STYLE: StyleSpecification = {
  version: 8,
  name: "Area Use Purpose Vector Tile Layer",
  glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
  sources: {
    areaUsePurpose: {
      type: "vector",
      // 本番環境にホストされているタイルのURL
      // 用途地域のデータが本番環境のものしか正しくないため、どの環境からもそちらを参照するようにしている
      tiles: ["https://d2w7h3d9f6f6fl.cloudfront.net/tiles/v2/{z}/{x}/{y}.pbf"],
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
    },
  },
  layers: [
    // ポリゴン
    {
      id: "type_polygon",
      type: "fill",
      source: SOURCE_NAME,
      "source-layer": SOURCE_LAYER_NAME,
      paint: {
        "fill-color": [
          "match",
          ["get", "t"],
          "1",
          "#00FF99",
          "2",
          "#00552E",
          "3",
          "#92D050",
          "4",
          "#CCFF99",
          "5",
          "#FFFF66",
          "6",
          "#FFCC99",
          "7",
          "#FFCC66",
          "8",
          "#FF66CC",
          "9",
          "#FF0066",
          "10",
          "#9900FF",
          "11",
          "#CCFFFF",
          "12",
          "#99CCFF",
          "#666666",
        ],
        "fill-outline-color": "#000000",
        "fill-opacity": 0.3,
      },
      layout: {
        visibility: "visible",
      },
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
    },
    // 選択時の赤枠ライン
    {
      id: "type_line",
      type: "line",
      source: SOURCE_NAME,
      "source-layer": SOURCE_LAYER_NAME,
      paint: {
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          1,
          0,
        ],
        "line-color": "#FF0000",
        "line-width": 3,
      },
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
    },
    // ラベル
    {
      id: "type_symbol",
      type: "symbol",
      source: SOURCE_NAME,
      "source-layer": SOURCE_LAYER_NAME,
      layout: {
        "text-field": [
          "match",
          ["get", "t"],
          "1",
          "第一種低層住居専用",
          "2",
          "第二種低層住居専用",
          "3",
          "第一種中高層住居専用",
          "4",
          "第二種中高層住居専用",
          "5",
          "第一種住居",
          "6",
          "第二種住居",
          "7",
          "準住居",
          "8",
          "近隣商業",
          "9",
          "商業",
          "10",
          "準工業",
          "11",
          "工業",
          "12",
          "工業専用",
          "不明",
        ],
        "text-font": ["Open Sans Regular"],
        "text-offset": [0, 1.25],
        "text-anchor": "top",
        "text-allow-overlap": false,
        "text-ignore-placement": false,
        "text-rotation-alignment": "map",
        "text-size": 14,
        "text-padding": 8,
      },
      paint: {
        "text-color": "#0c0063",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 3,
      },
      minzoom: MIN_ZOOM,
      maxzoom: MAX_ZOOM,
    },
  ],
};

/*
用途地域レイヤーのコンポーネント
ベクトルタイルを読み込むため、MapLibreをLeafletのレイヤーとして扱えるプラグインを使用している
*/
export const AreaUsePurposeTileLayer: React.FC<Props> = ({
  visible,
  initialZoomLevel,
}) => {
  // Leafletのマップオブジェクト
  const map = useMap();
  // レイヤー格納用
  const layerRef = useRef<L.MaplibreGL | null>(null);
  // ポップアップ格納用
  const popupRef = useRef<maplibregl.Popup | null>(null);
  // 選択中のID格納用
  const selectedIdRef = useRef<string | number | undefined>(undefined);
  // ズームレベル
  const [zoomLevel, setZoomLevel] = useState<number>(initialZoomLevel);

  // レイヤーをLeafletのマップオブジェクトに追加し、イベントの設定を行う関数
  const addLayerToMap = (vectorTileLayer: L.MaplibreGL, map: L.Map): void => {
    if (map.hasLayer(vectorTileLayer)) {
      return;
    }

    vectorTileLayer.addTo(map);

    // z-indexを設定しないとタイルレイヤーが見えないので必須
    vectorTileLayer.getContainer().style.zIndex = "2";

    // MapLibreのマップオブジェクト
    const maplibreMap = vectorTileLayer.getMaplibreMap();

    maplibreMap.on("load", () => {
      maplibreMap.on("click", "type_symbol", (e) => {
        // Leafletのマップオブジェクトのクリックイベントを抑止するためにstopPropagationを呼び出す
        e.originalEvent.stopPropagation();

        const features = e.features;
        if (features && features.length > 0) {
          const feature = features[0];

          // ポップアップ

          // eslint-disable-next-line @typescript-eslint/dot-notation
          const typeText = typeTextTable[feature.properties["t"] as string];
          // eslint-disable-next-line @typescript-eslint/dot-notation
          const buildingRate = feature.properties["br"] as number;
          // eslint-disable-next-line @typescript-eslint/dot-notation
          const volumeRate = feature.properties["vr"] as number;

          const description = `${typeText}<br>建ぺい率: ${buildingRate}%<br>容積率: ${volumeRate}%`;
          const coordinates = e.lngLat;

          if (selectedIdRef.current && popupRef.current) {
            // 存在する場合は削除
            popupRef.current.remove();
          }
          // 追加
          popupRef.current = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 20,
            className: "custom-maplibre-popup",
          })
            .setLngLat([coordinates.lng, coordinates.lat])
            .setHTML(description)
            .addTo(maplibreMap);

          // ハイライト用ライン
          // maplibreのfeature stateを使うには、featureにidが必要
          const aid = feature.id;
          // 存在する場合は削除
          if (selectedIdRef.current) {
            maplibreMap.setFeatureState(
              {
                source: SOURCE_NAME,
                sourceLayer: SOURCE_LAYER_NAME,
                id: selectedIdRef.current,
              },
              {
                selected: false,
              }
            );
          }
          // 追加
          maplibreMap.setFeatureState(
            {
              source: SOURCE_NAME,
              sourceLayer: SOURCE_LAYER_NAME,
              id: aid,
            },
            {
              selected: true,
            }
          );
          selectedIdRef.current = aid;
        }
      });
    });
  };

  useEffect(() => {
    if (!map) return;

    map.on("zoomend", (e) => {
      setZoomLevel(map.getZoom());
    });

    const vectorTileLayer = L.maplibreGL({
      interactive: true,
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      // Leafletと同調しなくなるので、falseにする
      scrollZoom: false,
      style: MAPLIBRE_STYLE,
    });

    layerRef.current = vectorTileLayer;
    if (visible) {
      addLayerToMap(vectorTileLayer, map);
    }

    return () => {
      if (map && layerRef.current) {
        map.removeLayer(layerRef.current);
      }
    };
  }, [map]);

  // レイヤーの表示制御
  // ベクトルタイルはLeaflet（ラスタタイル）よりもズームレベルが1だけ小さい
  useEffect(() => {
    if (layerRef.current) {
      if (
        visible &&
        zoomLevel &&
        zoomLevel - 1 >= MIN_ZOOM &&
        zoomLevel - 1 <= MAX_ZOOM
      ) {
        addLayerToMap(layerRef.current, map);
      } else {
        map.removeLayer(layerRef.current);
      }
    }
  }, [visible, zoomLevel, map]);

  return null;
};
