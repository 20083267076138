import { getYear } from "date-fns";
import { Stack, Grid } from "@mui/material";
import { CategoryContainer } from "@/features/mypage/components/CategoryContainer";
import { MenuButton } from "@/features/mypage/components/MenuButton";
import { getMenuData } from "@/configs/menuDataDefinition";
import { NewsSummaryContainer } from "@/features/mypage/components/NewsSummaryContainer";
import { TextLink } from "@/features/mypage/components/TextLink";
import { useLogin } from "@/hooks/useLogin";
import { useFeatureFlags } from "@/configs/featureFlag";

export const MyPage: React.FC = () => {
  const { logout } = useLogin();
  const handleClickLogout = (): void => {
    logout();
  };

  const {
    realEstateRegistrationAggregationOfNames,
    realEstateRegistrationMonitoring,
    commercialBookList,
    commercialRegistrationReceptionBook,
    commercialRegistrationAggregationOfNames,
    commercialRegistrationMonitoring,
    allMattersAnalysis,
    dmManagement,
    emailManagement,
    customerManagement,
    ownerInfoList,
    ownerInfoViaScraping,
  } = useFeatureFlags();

  // NOTE: フラグオン(true)は〈表示する〉なのだが、引数はDisable（非表示）かどうかなので逆転している
  const menuData = getMenuData({
    realEstateRegistrationAggregationOfNames:
      !realEstateRegistrationAggregationOfNames,
    commercialBookList: !commercialBookList,
    commercialRegistrationReceptionBook: !commercialRegistrationReceptionBook,
    realEstateRegistrationMonitoring: !realEstateRegistrationMonitoring,
    commercialRegistrationAggregationOfNames:
      !commercialRegistrationAggregationOfNames,
    commercialRegistrationMonitoring: !commercialRegistrationMonitoring,
    allMattersAnalysis: !allMattersAnalysis,
    dmManagement: !dmManagement,
    emailManagement: !emailManagement,
    customerManagement: !customerManagement,
    ownerInfoList: !ownerInfoList,
    ownerInfoViaScraping: !ownerInfoViaScraping,
  });

  return (
    <>
      <NewsSummaryContainer title={"新着情報"} year={getYear(Date.now())} />
      <Stack spacing={2} direction="column">
        {menuData.map((category) => (
          <CategoryContainer
            key={`mypage-${category.title}`}
            level={category.level}
            title={category.title}
            bgColor={category.bgColor}
          >
            <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 1 }}>
              {category.menus.map((menu) => {
                return (
                  <Grid
                    item
                    key={`mypage-${category.title}-grid-${menu.label}`}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={1}
                  >
                    <MenuButton
                      key={`mypage-${category.title}-${menu.label}`}
                      label={menu.label}
                      variant={menu.variant}
                      href={menu?.href}
                      description={menu?.description}
                      disabled={menu?.disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </CategoryContainer>
        ))}
        <TextLink onClickHandler={handleClickLogout}>ログアウト</TextLink>
      </Stack>
    </>
  );
};
