import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import {
  Button,
  ButtonVariantOption,
  SubmitButton,
} from "../../components/Button";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { FeedGrid } from "./components/FeedGrid";
import { KeyboardArrowLeft } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { type AcquireMultipleBookHandoffData } from "@/types/acquirebook";
import {
  type BookRequestCheckboxStatus,
  type BookRequestCheckboxStatuses,
} from "./types";
import { AlertBar } from "./components/AlertBar";
import { useFeatureFlags } from "@/configs/featureFlag";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface IAcquireMultipleBooksPresenterProps {
  handleSubmit: (
    rows: AcquireMultipleBookHandoffData[],
    checkBoxOptions: BookRequestCheckboxStatuses[],
    pastDays: number | "",
    withOwnerAnalyze: boolean,
    identificationName: string,
    cancelLoading: () => void
  ) => void;
  pastDays: number | "";
  setPastDays: React.Dispatch<React.SetStateAction<number | "">>;
}

export const DISTINCT_MESSAGE =
  "重複項目が見つかったため、自動で取り除きました";

// チェックボックスの初期状態を設定するための値を生成する
export const makeBookRequestCheckboxStatus = (
  checked: boolean,
  disabled: boolean
): BookRequestCheckboxStatus => {
  return { checked, disabled };
};

// チェックボックスの状態を管理するstateの初期値を生成する
export const makeOptionsInitialState = (
  rows: AcquireMultipleBookHandoffData[]
): BookRequestCheckboxStatuses[] => {
  // 土地の場合と建物の場合とでチェックボックスを表示するか非表示にするか判定する関数

  const checkDisable = (type: string, bookType: string): boolean => {
    // false: 表示, true: 非表示
    const table: Record<string, Record<string, boolean>> = {
      kyoutan: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      sintaku: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      genzai: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      ownerInfo: { LAND: false, BUILDING: false, CONDOMINIUM: false },
      electricDrawings: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      tisekiDrawings: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      tiekiDrawings: { LAND: false, BUILDING: true, CONDOMINIUM: true },
      buildingDrawings: { LAND: true, BUILDING: false, CONDOMINIUM: false },
    };
    return table[type][bookType] ?? false;
  };

  return rows.map((value) => {
    return {
      // ここでチェックボックスの初期状態を設定する
      kyoutan: makeBookRequestCheckboxStatus(
        false,
        checkDisable("kyoutan", value.bookType)
      ),
      sintaku: makeBookRequestCheckboxStatus(
        false,
        checkDisable("sintaku", value.bookType)
      ),
      genzai: makeBookRequestCheckboxStatus(
        false,
        checkDisable("genzai", value.bookType)
      ),
      ownerInfo: makeBookRequestCheckboxStatus(
        false,
        checkDisable("ownerInfo", value.bookType)
      ),
      electricDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("electricDrawings", value.bookType)
      ),
      tisekiDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("tisekiDrawings", value.bookType)
      ),
      tiekiDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("tiekiDrawings", value.bookType)
      ),
      buildingDrawings: makeBookRequestCheckboxStatus(
        false,
        checkDisable("buildingDrawings", value.bookType)
      ),
    };
  });
};

// 重複を取り除く
export const distinct = (
  rows: AcquireMultipleBookHandoffData[]
): AcquireMultipleBookHandoffData[] => {
  // 作業用の空配列を作る
  const workingArray: Array<{
    key: string;
    value: AcquireMultipleBookHandoffData;
  }> = [];

  rows.forEach((value) => {
    // 同一項目と識別するためのkeyを生成する
    const key = `${value.bookType}${value.prefName}${value.locationFull}${value.chibanKaokuNumber}`;

    // 作業用配列から上で生成したkeyと同じkeyを持っている項目があるか探す
    const anItemThatHasSameKey = workingArray.find((item) => item.key === key);

    if (anItemThatHasSameKey === undefined) {
      // 同じkeyを持っていないならそのkeyで作業用配列に新規追加
      workingArray.push({ key, value });
    }
    // 同じkeyを持っているならそのvalueは捨てる
  });

  // 最終的に出来上がった作業用配列からvalueのみを取り出す
  return workingArray.map((item, index): AcquireMultipleBookHandoffData => {
    // idを採番し直す
    item.value.id = index + 1;
    return item.value;
  });
};

export const AcquireMultipleBooksPresenter: React.FC<
  IAcquireMultipleBooksPresenterProps
> = ({ handleSubmit, pastDays, setPastDays }) => {
  // 確認画面のモーダルを表示するかしないかを決めるフラグ
  const [openModalFlag, setOpenModalFlag] = React.useState(false);
  const [withOwnerAnalyze, setWithOwnerAnalyze] = React.useState(false);
  // 遷移元の画面からデータを引き継ぐ
  const location = useLocation();
  // ここでデータがない場合は空配列にする
  const rows = (location.state as AcquireMultipleBookHandoffData[]) ?? [];
  // 重複を取り除く
  const distinctedRows = distinct(rows);

  // 重複があった場合に表示するメッセージを設定する
  const [message, setMessage] = useState(
    distinctedRows.length !== rows.length ? DISTINCT_MESSAGE : ""
  );

  // チェックボックスの状態を管理するstateを生成する
  const [checkBoxOptions, setCheckBoxOptions] = useState(
    makeOptionsInitialState(distinctedRows)
  );

  const isOwnerInfoChecked = (
    checkBoxOptions: BookRequestCheckboxStatuses[]
  ): boolean => {
    for (const option of checkBoxOptions) {
      if (option.ownerInfo.checked) {
        return true;
      }
    }
    return false;
  };

  // 経過日数の入力値をチェックする
  const checkPastDays = (value: string): void => {
    if (value === "" || !isNaN(Number(value))) {
      setPastDays(value === "" ? "" : Number(value));
    }
  };

  const { ownerInfoViaScraping, mypageTop } = useFeatureFlags();

  // Zodスキーマ定義
  const FormDataSchema = z.object({
    identifyName: z.string().min(1, "識別名は必須です"),
  });

  // 定義したZodのスキーマをTypescriptの型に変換
  type FormData = z.infer<typeof FormDataSchema>;

  // 型を用いてReact-Hook-Formのインスタンスを作る
  const reactHookForm = useForm<FormData>({
    mode: "all",
    defaultValues: {
      identifyName: "",
    },
    resolver: zodResolver(FormDataSchema),
  });

  return (
    <PagePaper>
      {message !== "" ? (
        <AlertBar
          message={message}
          onClose={() => {
            setMessage("");
          }}
        />
      ) : (
        ""
      )}
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ImportContactsIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>不動産登記/図面取得一括取得</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ ml: 0, color: "red", flexShrink: 0 }}>
              <ul>
                <li>最新の不動産登記/図面を取得いたします</li>
                <li>取得済みの場合は、課金が発生いたしません</li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md mb={1}>
            <Grid container spacing={1} justifyContent={"center"}>
              <Grid item xs={6}>
                <TextField
                  {...reactHookForm.register("identifyName")}
                  error={Boolean(reactHookForm.formState.errors.identifyName)}
                  helperText={
                    reactHookForm.formState.errors.identifyName?.message
                  }
                  label="識別名"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-pastdays">
                    前回取得日からの経過日数を指定する
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-pastdays"
                    type={"text"}
                    value={pastDays}
                    fullWidth={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      checkPastDays(event.target.value);
                    }}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        日
                        <Tooltip
                          title="取得済の場合でも経過日数を過ぎたものは再取得いたします"
                          placement="bottom"
                        >
                          <HelpIcon />
                        </Tooltip>
                      </InputAdornment>
                    }
                    label="前回取得日からの経過日数を指定する"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography fontWeight={"bold"} align={"right"}>
              列名をダブルクリックすると全選択する
            </Typography>
          </Grid>
        </Grid>
        <SectionPaper>
          <FeedGrid
            rows={distinctedRows}
            checkBoxOptions={checkBoxOptions}
            setCheckBoxOptions={setCheckBoxOptions}
          />
          {ownerInfoViaScraping ? (
            <Box
              sx={{
                mt: 6,
                mb: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <MuiCheckbox
                    sx={{ padding: "0px 4px" }}
                    checked={withOwnerAnalyze}
                    disabled={!isOwnerInfoChecked(checkBoxOptions)}
                    onChange={(e) => {
                      setWithOwnerAnalyze(e.target.checked);
                    }}
                  />
                }
                label="所有者事項謄本の取得と同時に内容を解析する"
              />
            </Box>
          ) : null}
          <Box
            sx={{
              mt: 1,
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant={ButtonVariantOption.Contained}
              onClick={() => {
                void (async () => {
                  // 現在がチェックかつ信託か共担のどちらかがチェックされていなかった場合、メッセージを表示する
                  const isInvalidOption = checkBoxOptions.some((value) => {
                    if (
                      value.genzai.checked &&
                      !(value.sintaku.checked || value.kyoutan.checked)
                    ) {
                      alert(
                        "現在事項を取得する場合には共担か信託を選択してください"
                      );
                      return true;
                    }
                    return false;
                  });
                  if (isInvalidOption) return;
                  // 一つもチェックがない場合、メッセージを表示する
                  const result = checkBoxOptions.some((value) => {
                    return (
                      Object.keys(value) as Array<
                        keyof BookRequestCheckboxStatuses
                      >
                    ).some((key) => value[key].checked);
                  });
                  if (!result) {
                    alert("取得したい謄本種別を最低一つはチェックしてください");
                    return;
                  }

                  // 一つでもチェックがあれば通す。チェックされていない行は取得登録されない
                  await reactHookForm.handleSubmit((_data, _event) => {
                    setOpenModalFlag(true);
                  })();
                })();
              }}
              label={"一括取得する"}
            />
          </Box>
        </SectionPaper>
        {/* 確認のモーダル画面 */}

        <Dialog
          open={openModalFlag}
          onClose={() => {
            setOpenModalFlag(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"確認画面"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              【課金が発生します】謄本/図面を取得します。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              variant={ButtonVariantOption.Contained}
              onClick={(_event, cancelLoading) => {
                handleSubmit(
                  distinctedRows,
                  checkBoxOptions,
                  pastDays,
                  withOwnerAnalyze,
                  reactHookForm.getValues("identifyName"),
                  cancelLoading
                );
              }}
              label={"取得する"}
              timeout={30000}
            />
            <Button
              variant={ButtonVariantOption.Outlined}
              onClick={() => {
                setOpenModalFlag(false);
              }}
              label={"キャンセル"}
            />
          </DialogActions>
        </Dialog>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
