import { Toolbar, AppBar, Box } from "@mui/material";
import { HeaderMenu } from "./components/HeaderMenu";
import { OrderButton } from "./components/OrderButton";
import { ManualInfoButton } from "./components/ManualInfoButton";
import logo from "../../assets/logo.svg";
import { BookListButton } from "./components/BookListButton";
import { useFeatureFlags } from "@/configs/featureFlag";
import { OwnerInfoAnalyzeButton } from "./components/OwnerInfoAnalyzeButton";
import { CommercialBookListButton } from "./components/CommercialBookListButton";
import { CustomerManagementLink } from "./components/CustomerManagementLink";
import { EmailManagementLink } from "./components/EmailManagementLink";
import { useLogin } from "@/hooks/useLogin";
import { InquiryButton } from "./components/InquiryBotton";
import { UpdateButton } from "@/features/header/components/UpdateButton";

const HeaderPresenter: React.FC = () => {
  const { isLoggedIn } = useLogin();

  const {
    customerManagement,
    commercialBookList,
    ownerInfoList,
    emailManagement,
    mypageTop,
  } = useFeatureFlags();

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <a href={mypageTop ? "/mypage" : "/feed"}>
            <img src={logo} alt="logo" height="32px" />
          </a>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isLoggedIn && (
            <>
              {!mypageTop && customerManagement && <CustomerManagementLink />}
              {!mypageTop && emailManagement && <EmailManagementLink />}
              {!mypageTop && <BookListButton />}
              {!mypageTop && ownerInfoList && <OwnerInfoAnalyzeButton />}
              {!mypageTop && commercialBookList && <CommercialBookListButton />}
            </>
          )}
          {!mypageTop && <InquiryButton />}
          {!mypageTop && <OrderButton />}
          <UpdateButton />
          {!mypageTop && <ManualInfoButton />}
          <HeaderMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { HeaderPresenter };
