// 不動産種別のvalue
export enum RealEstateTypeValues {
  BUILDING = "BUILDING",
  CONDOMINIUM = "CONDOMINIUM",
  LAND = "LAND",
}

// 不動産種別のlabel
export enum RealEstateTypeLabels {
  BUILDING = "建物",
  CONDOMINIUM = "区分建物",
  LAND = "土地",
}

// 謄本・図面種別のvalue
export enum PictureTypeValues {
  SHOYUSHAJIKO = "SHOYUSHAJIKO",
  ZENBUJIKO = "ZENBUJIKO",
  CHISEKISOKURYOZU = "CHISEKISOKURYOZU",
  DENSHIKOZU = "DENSHIKOZU",
  CHIEKIKENZUMEN = "CHIEKIKENZUMEN",
  TATEMONOZUMEN = "TATEMONOZUMEN",
}

// 謄本・図面種別のlabel
export enum PictureTypeLabels {
  SHOYUSHAJIKO = "所有者事項",
  ZENBUJIKO = "全部事項",
  CHISEKISOKURYOZU = "地積測量図",
  DENSHIKOZU = "電子公図",
  CHIEKIKENZUMEN = "地役権図面",
  TATEMONOZUMEN = "建物図面",
}
