import { useState, useEffect } from "react";
import { RealEstateReceptionBookFeedPresenter } from "./presenter";
import { useCityOptions } from "./hooks/useCityOptions";
import { useCitySelect } from "./hooks/useCitySelect";
import { usePrefectureOptions } from "./hooks/usePrefectureOptions";
import { useReceptionReasonOptions } from "./hooks/useReceptionReasonOptions";
import { type IFilterCondition } from "./types";
import { buildPrefectureAndCitySetter } from "./hooks/setPrefecturesWithCities";
import { useReceptionBookImportStatuses } from "./hooks/useReceptionBookImportStatuses";
import { useGridApiRef } from "@mui/x-data-grid";
import { type GridApiCommunity } from "@mui/x-data-grid/models/api/gridApiCommunity";
import {
  getStartAndEndDate,
  getSearchMinDate,
  getSearchMaxDate,
  isDateDiffOverOneYear,
} from "./utils/dateTime";
import { useStationLineOptions } from "./hooks/useStationLineOptions";
import { useStationOptions } from "./hooks/useStationOptions";
import { type ISelectObject } from "@/types/select";

const RealEstateReceptionBookFeedContainer: React.FC = () => {
  const [startDate, endDate] = getStartAndEndDate();
  const minDate = getSearchMinDate();
  const maxDate = getSearchMaxDate();

  // ---
  // hooks群
  // ---
  const gridApiRef: React.MutableRefObject<GridApiCommunity> = useGridApiRef();

  const [
    legalAffairsBureauRequestDateStart,
    setLegalAffairsBureauRequestDateStart,
  ] = useState<Date | null>(startDate);
  const [
    legalAffairsBureauRequestDateEnd,
    setLegalAffairsBureauRequestDateEnd,
  ] = useState<Date | null>(endDate);
  const [
    realEstateBookTypeRensakiRenzoku,
    setRealEstateBookTypeRensakiRenzoku,
  ] = useState<boolean>(false);
  const [realEstateBookTypeTandoku, setRealEstateBookTypeTandoku] =
    useState<boolean>(false);
  const [realEstateTypeTochi, setRealEstateTypeTochi] =
    useState<boolean>(false);
  const [realEstateTypeKutate, setRealEstateTypeKutate] =
    useState<boolean>(false);
  const [realEstateTypeTatemono, setRealEstateTypeTatemono] =
    useState<boolean>(false);
  const [realEstateTypeKyotan, setRealEstateTypeKyotan] =
    useState<boolean>(false);
  const [hasTrade, setHasTrade] = useState<boolean>(false);
  const [isTowerApartment, setIsTowerApartment] = useState<boolean>(false);
  const [isOneRoom, setIsOneRoom] = useState<boolean>(false);
  const [fileToCsvUpload, setFileToCsvUpload] = useState<File | null>(null);
  const [cities, setCities] = useState<string[]>([]);
  const [prefectures, setPrefectures] = useState<string[]>([]);
  const [receptionReasons, setReceptionReasons] = useState<string[]>([]);
  const [sotoRange, setSotoRange] = useState<Record<string, number | null>>({
    min: null,
    max: null,
  });
  const [estimateAcreageRange, setEstimateAcreageRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [estimateLandPriceRange, setEstimateLandPriceRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [approximateEstimatePriceRange, setApproximateEstimatePriceRange] =
    useState<Record<string, number | null>>({
      min: null,
      max: null,
    });
  const [residenceOptions, setResidenceOptions] = useState<
    Record<string, boolean>
  >({
    ittei: false,
    icchuko: false,
    ichiju: false,
    nitei: false,
    nichuko: false,
    niju: false,
    denju: false,
    junju: false,
  });
  const [commercialOptions, setCommercialOptions] = useState<
    Record<string, boolean>
  >({
    kinsyo: false,
    syogyo: false,
  });
  const [industryOptions, setIndustryOptions] = useState<
    Record<string, boolean>
  >({
    junko: false,
    kogyo: false,
    kosen: false,
  });
  const [yosekiRatioRange, setYosekiRatioRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [kenpeiRatioRange, setKenpeiRatioRange] = useState<
    Record<string, number | null>
  >({
    min: null,
    max: null,
  });
  const [buildDateStart, setBuildDateStart] = useState<Date | null>(null);
  const [buildDateEnd, setBuildDateEnd] = useState<Date | null>(null);
  const [propertyName, setPropertyName] = useState<string>("");

  // 選択した駅項目を保持しておくstate
  const [stationSelectedValues, setStationSelectedValues] = useState<
    ISelectObject[]
  >([]);

  const [stationLine, setStationLine] = useState<number>(-1);
  const [walk, setWalk] = useState<number>(-1);

  const [filterCondition, setFilterCondition] = useState<IFilterCondition>({
    legalAffairsBureauRequestDateStart,
    legalAffairsBureauRequestDateEnd,
    realEstateBookTypeRensakiRenzoku,
    realEstateBookTypeTandoku,
    realEstateTypeTochi,
    realEstateTypeKutate,
    realEstateTypeTatemono,
    realEstateTypeKyotan,
    cities,
    prefectures,
    receptionReasons,
    sotoRange,
    estimateAcreageRange,
    estimateLandPriceRange,
    approximateEstimatePriceRange,
    residenceOptions,
    commercialOptions,
    industryOptions,
    yosekiRatioRange,
    kenpeiRatioRange,
    hasTrade,
    isTowerApartment,
    isOneRoom,
    buildDateStart,
    buildDateEnd,
    propertyName,
    stationSelectedValues,
    walk,
  });

  // 謄本取得モーダル用state
  const [requestOptions, setRequestOptions] = useState<
    Record<string, Record<string, boolean>>
  >({
    syoyusya: { checked: false, disable: true },
    zennbu: { checked: false, disable: true },
    sintaku: { checked: false, disable: true },
    kyodo: { checked: false, disable: true },
    genzai: { checked: false, disable: true },
    tiseki: { checked: false, disable: true },
    densi: { checked: false, disable: true },
    tieki: { checked: false, disable: true },
    tatemono: { checked: false, disable: true },
  });

  // 謄本個別取得モーダル用の前回取得日からの経過日数テキストボックス
  const [pastDays, setPastDays] = useState<number>(0);

  // ---
  // custom hooks群
  // ---
  const { prefectureOptions } = usePrefectureOptions();
  const { cityParams } = useCityOptions();
  const { cityOptions } = useCitySelect(prefectures, cityParams);
  const { receptionReasonOptions } = useReceptionReasonOptions();
  const importStatuses = useReceptionBookImportStatuses();

  // 路線一覧のセレクト項目
  const { stationLineOptions } = useStationLineOptions();
  // 駅一覧のセレクト項目
  const { stationOptions } = useStationOptions(stationLine);

  // ---
  // function群
  // ---
  const validateDate = (date: Date | null, dateName: string): string => {
    const dateInvalid = !date || isNaN(date.getDate());
    const dateOutOfRange = date && (date < minDate || date > maxDate);

    return (
      `${
        dateInvalid
          ? `法務局受付日 ${dateName}を${
              !date ? "選択して下さい" : "正しい日付の形式にして下さい"
            }\n`
          : ""
      }` + `${dateOutOfRange ? `法務局受付日 ${dateName}が範囲外です\n` : ""}`
    );
  };

  const handleSearch = (): void => {
    let alertMessage = "";
    if (!cities.length) {
      alertMessage += "市区町村を選択して下さい\n";
    }
    if (!receptionReasons.length) {
      alertMessage += "登記原因を選択して下さい\n";
    }
    alertMessage += validateDate(legalAffairsBureauRequestDateStart, "開始");
    alertMessage += validateDate(legalAffairsBureauRequestDateEnd, "終了");

    if (alertMessage !== "") {
      alert(alertMessage);
      return;
    }
    setFilterCondition({
      legalAffairsBureauRequestDateStart,
      legalAffairsBureauRequestDateEnd,
      realEstateBookTypeRensakiRenzoku,
      realEstateBookTypeTandoku,
      realEstateTypeTochi,
      realEstateTypeKutate,
      realEstateTypeTatemono,
      realEstateTypeKyotan,
      cities,
      prefectures,
      receptionReasons,
      sotoRange,
      estimateAcreageRange,
      estimateLandPriceRange,
      approximateEstimatePriceRange,
      residenceOptions,
      commercialOptions,
      industryOptions,
      yosekiRatioRange,
      kenpeiRatioRange,
      hasTrade,
      stationSelectedValues,
      walk,
      isTowerApartment,
      isOneRoom,
      buildDateStart,
      buildDateEnd,
      propertyName,
    });
  };

  const setPrefectureAndCity = buildPrefectureAndCitySetter(
    cityParams,
    cities,
    setCities,
    setPrefectures
  );

  useEffect(() => {
    if (
      legalAffairsBureauRequestDateEnd &&
      legalAffairsBureauRequestDateStart
    ) {
      const isOneYear = isDateDiffOverOneYear(
        legalAffairsBureauRequestDateStart,
        legalAffairsBureauRequestDateEnd
      );
      if (isOneYear) {
        const date = new Date(legalAffairsBureauRequestDateStart);
        date.setFullYear(legalAffairsBureauRequestDateStart.getFullYear() + 1);
        setLegalAffairsBureauRequestDateEnd(date);
      }
    }
  }, [legalAffairsBureauRequestDateStart]);

  useEffect(() => {
    if (
      legalAffairsBureauRequestDateEnd &&
      legalAffairsBureauRequestDateStart
    ) {
      const isOneYear = isDateDiffOverOneYear(
        legalAffairsBureauRequestDateStart,
        legalAffairsBureauRequestDateEnd
      );
      if (isOneYear) {
        const date = new Date(legalAffairsBureauRequestDateEnd);
        date.setFullYear(date.getFullYear() - 1);
        setLegalAffairsBureauRequestDateStart(date);
      }
    }
  }, [legalAffairsBureauRequestDateEnd]);
  return (
    <RealEstateReceptionBookFeedPresenter
      setLegalAffairsBureauRequestDateStart={
        setLegalAffairsBureauRequestDateStart
      }
      setLegalAffairsBureauRequestDateEnd={setLegalAffairsBureauRequestDateEnd}
      setRealEstateBookTypeTandoku={setRealEstateBookTypeTandoku}
      setRealEstateBookTypeRensakiRenzoku={setRealEstateBookTypeRensakiRenzoku}
      setRealEstateTypeTochi={setRealEstateTypeTochi}
      setRealEstateTypeKutate={setRealEstateTypeKutate}
      setRealEstateTypeTatemono={setRealEstateTypeTatemono}
      setRealEstateTypeKyotan={setRealEstateTypeKyotan}
      setReceptionReasons={setReceptionReasons}
      setCities={setCities}
      setPrefectures={setPrefectureAndCity}
      setStationSelectedValues={setStationSelectedValues}
      setStationLine={setStationLine}
      setWalk={setWalk}
      setSotoRange={setSotoRange}
      setEstimateAcreageRange={setEstimateAcreageRange}
      setEstimateLandPriceRange={setEstimateLandPriceRange}
      setApproximateEstimatePriceRange={setApproximateEstimatePriceRange}
      setResidenceOptions={setResidenceOptions}
      setCommercialOptions={setCommercialOptions}
      setIndustryOptions={setIndustryOptions}
      setYosekiRatioRange={setYosekiRatioRange}
      setKenpeiRatioRange={setKenpeiRatioRange}
      handleSearch={handleSearch}
      setHasTrade={setHasTrade}
      setIsTowerApartment={setIsTowerApartment}
      setIsOneRoom={setIsOneRoom}
      setBuildDateStart={setBuildDateStart}
      setBuildDateEnd={setBuildDateEnd}
      setPropertyName={setPropertyName}
      setFileToCsvUpload={setFileToCsvUpload}
      cities={cities}
      prefectures={prefectures}
      receptionReasons={receptionReasons}
      stationSelectedValues={stationSelectedValues}
      stationLine={stationLine}
      walk={walk}
      realEstateBookTypeTandoku={realEstateBookTypeTandoku}
      realEstateBookTypeRensakiRenzoku={realEstateBookTypeRensakiRenzoku}
      legalAffairsBureauRequestDateStart={legalAffairsBureauRequestDateStart}
      legalAffairsBureauRequestDateEnd={legalAffairsBureauRequestDateEnd}
      realEstateTypeTochi={realEstateTypeTochi}
      realEstateTypeKutate={realEstateTypeKutate}
      realEstateTypeTatemono={realEstateTypeTatemono}
      realEstateTypeKyotan={realEstateTypeKyotan}
      cityOptions={cityOptions}
      prefectureOptions={prefectureOptions}
      stationLineOptions={stationLineOptions}
      stationOptions={stationOptions}
      importStatuses={importStatuses}
      receptionReasonOptions={receptionReasonOptions}
      filterCondition={filterCondition}
      startDate={startDate}
      minDate={minDate}
      sotoRange={sotoRange}
      estimateAcreageRange={estimateAcreageRange}
      estimateLandPriceRange={estimateLandPriceRange}
      approximateEstimatePriceRange={approximateEstimatePriceRange}
      residenceOptions={residenceOptions}
      commercialOptions={commercialOptions}
      industryOptions={industryOptions}
      yosekiRatioRange={yosekiRatioRange}
      kenpeiRatioRange={kenpeiRatioRange}
      maxDate={maxDate}
      hasTrade={hasTrade}
      isTowerApartment={isTowerApartment}
      isOneRoom={isOneRoom}
      buildDateStart={buildDateStart}
      buildDateEnd={buildDateEnd}
      propertyName={propertyName}
      fileToCsvUpload={fileToCsvUpload}
      requestOptions={requestOptions}
      setRequestOptions={setRequestOptions}
      gridApiRef={gridApiRef}
      pastDays={pastDays}
      setPastDays={setPastDays}
    />
  );
};

export { RealEstateReceptionBookFeedContainer };
