import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import React from "react";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { type GridColDef, type GridRenderCellParams } from "@mui/x-data-grid";
import { EmailManagementListDataGrid } from "../../../components/DataGrid";
import {
  type DataGridRow,
  type DownloadCondition,
  type StoredPicturesFeedGridProps,
} from "../types";
import {
  PictureTypeLabels,
  PictureTypeValues,
  RealEstateTypeLabels,
  RealEstateTypeValues,
} from "../types/enums";
import { IconButton } from "@mui/material";
import { downloadAPI } from "../api";
import { type AxiosInstance } from "axios";

const StyledDataGrid = styled(EmailManagementListDataGrid)`
  // テーブルのセルをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  // テーブルのカラムヘッダをクリックしても青い枠が表示されないようにする
  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  // テーブルヘッダーの背景色を設定
  .MuiDataGrid-columnHeaders {
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5em !important;
  }

  // テーブルタイトルを太字に
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
`;

const makeGridColDef = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDownloadCondition: React.Dispatch<React.SetStateAction<DownloadCondition>>,
  setSelectRow: React.Dispatch<React.SetStateAction<DataGridRow>>,
  apiClient: AxiosInstance
): GridColDef[] => {
  // ダウンロードボタンクリック時に実行。
  function handleClick(row: DataGridRow): void {
    if (row.lastUserAcquisitionDate != null) {
      // lastUserAcquisitionDateがnullでなければ、モーダルを表示せず無料でダウンロード。
      (async () => {
        await downloadAPI(apiClient, { ids: [String(row.id)], price: 0 }, row);
      })();
    } else {
      // lastUserAcquisitionDateがnullならば、モーダル表示。
      setIsOpen(true);
      setSelectRow(row);
      setDownloadCondition({
        ids: [String(row.id)],
        price: row.chargeAmount,
      });
    }
  }

  return [
    {
      field: "realEstateType",
      headerName: "種別",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === RealEstateTypeValues.BUILDING)
          return RealEstateTypeLabels.BUILDING;
        else if (value === RealEstateTypeValues.CONDOMINIUM)
          return RealEstateTypeLabels.CONDOMINIUM;
        else return RealEstateTypeLabels.LAND;
      },
    },
    {
      field: "pictureType",
      headerName: "事項種別",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        if (value === PictureTypeValues.SHOYUSHAJIKO)
          return PictureTypeLabels.SHOYUSHAJIKO;
        else if (value === PictureTypeValues.ZENBUJIKO)
          return PictureTypeLabels.ZENBUJIKO;
        else return null;
      },
    },
    {
      field: "prefectureName",
      headerName: "都道府県",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "所在",
      width: 300,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "receivedDate",
      headerName: "システム取得日",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        return value != null ? format(new Date(value), "yyyy/MM/dd") : "";
      },
    },
    {
      field: "isDownloadable",
      headerName: "ダウンロード",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams<boolean, DataGridRow>) => {
        return (
          <IconButton
            aria-label="download-icon"
            color="primary"
            onClick={() => {
              handleClick(row);
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        );
      },
    },
    {
      field: "lastUserAcquisitionDate",
      headerName: "あなたが取得した日",
      width: 180,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams<string>) => {
        return value != null ? format(new Date(value), "yyyy/MM/dd") : "";
      },
    },
  ];
};

export const StoredPicturesFeedGrid: React.FC<StoredPicturesFeedGridProps> = ({
  rows,
  setDownloadCondition,
  setIsOpen,
  setSelectRow,
  apiClient,
}) => {
  const colDef = makeGridColDef(
    setIsOpen,
    setDownloadCondition,
    setSelectRow,
    apiClient
  );

  return (
    <StyledDataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={true}
      rows={rows}
      columns={colDef}
    />
  );
};
