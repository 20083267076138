import {
  Box,
  Button as MuiButton,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React, { useEffect, useState } from "react";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { FeedGrid } from "../components/CommercialBookListFeedGrid";
import { AlertBar } from "@/features/acquireMultipleBooks/components/AlertBar";
import type { HandOffMessage } from "@/types/handOffMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "@/configs/featureFlag";

export const CommercialBookList: React.FC = () => {
  // 遷移元の画面からデータを引き継ぐ
  const location = useLocation();
  const navigate = useNavigate();
  const { mypageTop } = useFeatureFlags();

  // メッセージを表示するためのState、 ""の場合表示しない
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    // 前の画面から引き継いだメッセージを確認する
    const locationState = location.state as Record<string, unknown>;
    if (locationState?.handOffMessage) {
      const message = locationState.handOffMessage as HandOffMessage;
      if (message.command === "COMMERCIAL_INFO_SHOW_ACCEPT_MESSAGE") {
        setAlertMessage(
          "商業謄本取得を受け付けました。取得状況は一覧から確認できます。"
        );
      }
    }
  }, []);

  return (
    <PagePaper>
      {alertMessage !== "" ? (
        <AlertBar
          severity="info"
          message={alertMessage}
          onClose={() => {
            setAlertMessage("");
            const locationState = location.state as Record<string, unknown>;
            if (locationState?.handOffMessage) {
              locationState.handOffMessage = undefined;
              // location.stateの値を更新するためにnavigate()を使って更新する
              // こうしないと再読み込みでlocation.stateが元の値に戻ってしまう
              navigate(".", { replace: true, state: locationState });
            }
          }}
        />
      ) : (
        ""
      )}
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ImportContactsIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>商業謄本取得一覧</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <Stack my={2} direction="row" justifyContent="end" spacing={1}>
              <MuiButton
                variant="contained"
                size="large"
                component={Link}
                href={"/commercialbook"}
              >
                商業謄本取得
              </MuiButton>
            </Stack>
          </Grid>
        </Grid>
        <SectionPaper>
          <FeedGrid />
          <Box sx={{ mb: 6 }} />
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"center"}
            mb={2}
          ></Stack>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
