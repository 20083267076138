import { ButtonVariantOption, ButtonColorOption } from "@/components/Button";
import { StyledDataGrid } from "./DataGrid";
import { type PropertySelectionRow } from "../types";
import {
  type GridColDef,
  type GridSelectionModel,
  type GridRenderCellParams,
  type GridColumnHeaderParams,
} from "@mui/x-data-grid";
import { useCallback, useState, useEffect } from "react";
import { Box, Button as MuiButton } from "@mui/material";
import { SizingWrapperStyle } from "@/components/Wrapper";

interface IProps {
  rows: PropertySelectionRow[];
  setRows: React.Dispatch<React.SetStateAction<PropertySelectionRow[]>>;
}

export const PropertySelectionGrid: React.FC<IProps> = (props) => {
  const { rows, setRows } = props;

  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>(() =>
    rows.filter((row) => row.selected).map((row) => row.id)
  );

  useEffect(() => {
    setSelectedRowIds(rows.filter((row) => row.selected).map((row) => row.id));
  }, [rows]);

  const handleRowSelectionChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedRowIds(newSelectionModel);
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          selected: newSelectionModel.includes(row.id),
        }))
      );
    },
    [setRows]
  );

  const handleDelete = useCallback(
    (id: number) => {
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    },
    [setRows]
  );

  const convertFullWidthDigitsAndHyphenToHalfWidth = (str: string): string => {
    return str.replace(/[０-９－]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
    });
  };

  // カラム設定
  const columns: GridColDef[] = [
    {
      field: "id",
      width: 30,
      align: "center",
    },
    {
      field: "bookType",
      headerName: "種別",
      width: 70,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        if (value === "LAND") {
          return "土地";
        } else if (value === "BUILDING") {
          return "建物";
        } else if (value === "CONDOMINIUM") {
          return "区分建物";
        } else if (value === "ONE_BUILDING") {
          return "一棟";
        } else {
          return "";
        }
      },
    },
    {
      field: "location",
      headerName: "所在",
      width: 240,
      sortable: false,
      align: "left",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        // 長い文字列の場合は折り返す
        return <Box sx={{ whiteSpace: "normal" }}>{value}</Box>;
      },
    },
    {
      field: "chibanKaokuNumber",
      headerName: "地番または家屋番号",
      width: 100,
      sortable: false,
      align: "left",
      headerAlign: "center",
      renderCell: ({ value }: GridRenderCellParams) => {
        return convertFullWidthDigitsAndHyphenToHalfWidth(value as string);
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          地番または
          <br />
          家屋番号
        </strong>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box>
            <MuiButton
              size={SizingWrapperStyle.SMALL}
              variant={ButtonVariantOption.Outlined}
              color={ButtonColorOption.Error}
              onClick={() => {
                handleDelete(params.id as number);
              }}
            >
              削除する
            </MuiButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <StyledDataGrid
        disableColumnMenu
        disableSelectionOnClick={true}
        rows={rows}
        columns={columns}
        checkboxSelection
        selectionModel={selectedRowIds}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        onSelectionModelChange={handleRowSelectionChange}
      />
    </Box>
  );
};
