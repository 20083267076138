import {
  Box,
  Button as MuiButton,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import LinearProgress, {
  type LinearProgressProps,
} from "@mui/material/LinearProgress";
import {
  LinkButton,
  ButtonVariantOption,
  SubmitButton,
} from "../../components/Button";
import { PagePaper, SectionPaper } from "@/components/Paper";
import { PageTitle } from "@/components/Title";
import { FeedGrid } from "./components/FeedGrid";
import { KeyboardArrowLeft } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { useAcquireBookStatus } from "./hooks/useAcquireBookStatus";
import { type AcquireBookStatus } from "@/features/acquireBookStatus/types";
import { useApiClient } from "@/hooks/useApiClient";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { type HandOffMessage } from "@/types/handOffMessage";
import { useFeatureFlags } from "@/configs/featureFlag";
import Tooltip from "@mui/material/Tooltip";
import { acquireBookIsDone } from "@/utils/utilsBookStatus";

export const AcquireBookStatusPresenter: React.FC = () => {
  const { id } = useParams();

  const { data, getStatusString, getRemainingCountWithErrorCount } =
    useAcquireBookStatus(id);
  const [remainingCount] = getRemainingCountWithErrorCount(data);
  const navigate = useNavigate();
  const { apiClient } = useApiClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [acquisitionCompletionTime, setAcquisitionCompletionTime] = useState<
    number | null
  >(null);
  const [startTime] = useState(new Date());
  const acquireBookDetails: AcquireBookStatus[] = data?.list ?? [];
  const [selectedOwnerInfos, setSelectedOwnerInfos] = useState<
    Map<number, boolean>
  >(
    acquireBookDetails.reduce<Map<number, boolean>>((a, x) => {
      a.set(x.id, false);
      return a;
    }, new Map<number, boolean>())
  );
  const handleOwnerAnalyzeButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cancelLoading: () => void
  ): void => {
    const detailIds: number[] = [];
    for (const [detailId, checked] of selectedOwnerInfos) {
      if (checked) {
        detailIds.push(detailId);
      }
    }
    const body = {
      detailIds,
    };
    apiClient.post("/owner-analyze/picture-request", body).catch((e) => {
      Sentry.captureException(e);
      cancelLoading();
      toast.error("所有者事項解析に失敗しました");
    });

    // 上のapiClient.post()はawaitしてないしthen()も無いため、成功しても失敗してもすぐに下のコードが実行される

    const handOffMessage: HandOffMessage = {
      command: "OWNER_INFO_SHOW_ACCEPT_MESSAGE",
    };

    // 成功した場合は次の画面へ遷移するのでcancelLoading()の呼び出しは不要
    navigate("/ownerinfo/list", {
      replace: true,
      state: { handOffMessage },
    });
  };

  const acStatusMessage = getStatusString(data);
  // data?.countが途中でundefinedから10とか5とかに変化するけど、それに反応してくれない、ここはバックエンドからの値によって定期的に変化するので更新には追従してほしい
  const drawingCount = remainingCount ?? 0; // 図面数
  const estimatedTimePerDrawing = 600; // 図面1個あたりの予測時間(秒)(仮置き)
  // TODO 見た目を確認するための仮置き
  // １秒ごとに処理を実行して経過時間を更新しているため、再読み込みしなくても経過時間が更新される
  const updateProgressAndTime = (
    setAcquisitionCompletionTime: React.Dispatch<
      React.SetStateAction<number | null>
    >,
    setProgress: React.Dispatch<React.SetStateAction<number>>
  ): void => {
    const now = new Date();
    const elapsedTime = Math.floor(
      (now.getTime() - startTime.getTime()) / 60000
    ); // 経過時間(分)
    const estimatedTotalTime = (estimatedTimePerDrawing / 60) * drawingCount; // 所要時間予測(分)
    const remainingTime = Math.max(estimatedTotalTime - elapsedTime, 0); // 取得完了見込み時間(分)
    setAcquisitionCompletionTime(remainingTime);
    setProgress((elapsedTime / estimatedTotalTime) * 100);
  };

  useEffect(() => {
    updateProgressAndTime(setAcquisitionCompletionTime, setProgress);
    const interval: number = window.setInterval(() => {
      updateProgressAndTime(setAcquisitionCompletionTime, setProgress);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [startTime]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const LinearProgressWithLabel: React.FC<
    LinearProgressProps & { value: number }
  > = (props: LinearProgressProps & { value: number }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            fontWeight={"bold"}
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "15px", color: "blue" }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const shouldShowTooltip = (): boolean => {
    // 処理が完了しているかつzipFileUriが存在しない場合はtrue
    if (acStatusMessage === "") {
      return false;
    }
    return acquireBookIsDone(acStatusMessage) && !data?.zipFileUri;
  };

  const { ownerInfoViaScraping, mypageTop } = useFeatureFlags();

  return (
    <PagePaper>
      <form>
        <Box sx={{ display: "flex" }}>
          {/* ページタイトル */}
          <Box sx={{ display: "flex", mb: 3 }}>
            <ImportContactsIcon sx={{ mr: 1 }} fontSize="large" />
            <PageTitle>不動産登記/図面取得一括取得待ち</PageTitle>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={3} sx={{ ml: 0, color: "red" }}>
            <Typography
              fontWeight={"bold"}
              sx={{ color: "#4A86DF", fontSize: "20px" }}
            >
              ステータス: {acStatusMessage}
            </Typography>
          </Grid>
          {/* 一時非表示にする */}
          {/* <Grid item xs> */}
          {/* <Stack sx={{ width: "50%" }}> */}
          {/* <Stack direction="row"> */}
          {/*   <Typography fontWeight={"bold"} style={{ color: "#4A86DF" }}> */}
          {/*     取得完了見込み時間: */}
          {/*   </Typography> */}
          {/*   <Typography */}
          {/*     display="inline" */}
          {/*     fontWeight={"bold"} */}
          {/*     style={{ color: "#4A86DF" }} */}
          {/*   > */}
          {/*     残り */}
          {/*     {acquisitionCompletionTime === null */}
          {/*       ? "" */}
          {/*       : `${acquisitionCompletionTime}分`} */}
          {/*   </Typography> */}
          {/* </Stack> */}

          {/* <Box> */}
          {/*   <LinearProgressWithLabel value={progress} /> */}
          {/* </Box> */}
          {/* </Stack> */}
          {/* </Grid> */}
        </Grid>
        <SectionPaper>
          <FeedGrid
            row={data?.list}
            selectedOwnerInfos={selectedOwnerInfos}
            setSelectedOwnerInfos={setSelectedOwnerInfos}
            bindOwnerAnalyzeRequestId={data?.bindOwnerAnalyzeRequestId}
          />
          <Box sx={{ mb: 6 }} />
          <Stack direction={"row"} spacing={4} justifyContent={"center"} mb={2}>
            <Tooltip
              title={
                shouldShowTooltip() ? (
                  <>
                    一括ダウンロード用のファイルを作成中です。
                    <br />
                    数分間かかる場合があります。
                  </>
                ) : (
                  ""
                )
              }
              placement="top"
              arrow
              disableHoverListener={!shouldShowTooltip()}
            >
              <span>
                <LinkButton
                  variant={ButtonVariantOption.Contained}
                  disabled={!data?.zipFileUri}
                  href={data?.zipFileUri ?? ""}
                >
                  一括ダウンロードする
                </LinkButton>
              </span>
            </Tooltip>
            {ownerInfoViaScraping && data?.bindOwnerAnalyzeRequestId === null && (
              <SubmitButton
                // 1件でも解析にチェックがあれば有効とする
                disabled={
                  ![...selectedOwnerInfos.values()].some((value) => value)
                }
                variant={ButtonVariantOption.Contained}
                onClick={handleOwnerAnalyzeButtonClick}
                label={"所有者事項を解析する"}
                timeout={30000}
              />
            )}
            {ownerInfoViaScraping && data?.bindOwnerAnalyzeRequestId && (
              <MuiButton
                variant={ButtonVariantOption.Contained}
                component={RouterLink}
                to={`/ownerinfo/detail/${data?.bindOwnerAnalyzeRequestId}`}
              >
                解析済みの所有者事項を確認する
              </MuiButton>
            )}
          </Stack>
        </SectionPaper>

        {/* 戻る */}
        <Box sx={{ mt: 2 }}>
          <Link
            href={mypageTop ? "/mypage" : "/feed"}
            sx={{ display: "inline-block" }}
          >
            <Box sx={{ display: "flex" }}>
              <KeyboardArrowLeft />
              <Typography>
                {mypageTop ? "マイページに戻る" : "不動産登記受付帳検索に戻る"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
    </PagePaper>
  );
};
