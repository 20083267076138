import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { PageTitle } from "@/components/Title";
import { Label } from "@/components/Label";
import React, { useEffect, useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Button,
  ButtonVariantOption,
  ButtonColorOption,
} from "@/components/Button";
import { SizingWrapperStyle } from "@/components/Wrapper";
import { type CustomerStatusDataGridRow } from "@/features/customerManagement/types";
import { CustomModal } from "@/components/Modal";
import { CustomerListFeedGrid } from "../components/MailScheduleCreator/CustomerListFeedGrid";
import { MailStatus } from "../components/MailStatus";
import { useNavigate, useParams } from "react-router-dom";
import {
  CancelMailDetailAPI,
  DeleteMailDetailAPI,
  DuplicateMailDetailAPI,
} from "../api";
import { useApiClient } from "@/hooks/useApiClient";
import { toast } from "react-toastify";
import { useMailDetailAPI } from "../hooks/useMailDetailAPI";
import {
  MailStatusMapping,
  type MailDetail as MailDetailType,
} from "../api/types";
import MailPageTemplate from "../components/MailPageTemplate";
import { DestinationTypeLabels, DestinationTypeValues } from "../types/enums";

export const MailDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mailId = parseInt(id as string);
  const { data, refetch } = useMailDetailAPI(mailId);
  // 顧客リストから選択された顧客リスト
  const [sendRows, setSendRows] = useState<CustomerStatusDataGridRow[]>([]);
  const { apiClient } = useApiClient();

  // モーダルの表示状態を画面全体で管理
  const [isModalOpen, setIsModalOpen] = useState({
    delete: false,
    cancel: false,
  });

  // メール詳細フォームの入力値
  const [emailSchedule, setEmailSchedule] = useState<MailDetailType>({
    status: "saved",
    title: "",
    body: "",
    destinationType: DestinationTypeValues.ALL,
    destinationConditions: null,
    clients: [],
    scheduledSendAt: "",
  });

  // モーダルの表示状態を切り替える
  const toggleModal = (type: "delete" | "cancel", isOpen: boolean): void => {
    setIsModalOpen((prev) => ({ ...prev, [type]: isOpen }));
  };

  // 削除ボタンで「はい」を押したとき、メールを削除し一覧画面に遷移。
  const handleDelete = async (): Promise<void> => {
    const data = await DeleteMailDetailAPI(apiClient, {
      mailSendIds: [mailId],
    });
    if (data != null) {
      toast.success("メールの削除に成功しました。");
      toggleModal("delete", false);
      if (emailSchedule.status === "saved") {
        navigate("/emailManagement/mail/savedEmailList");
      } else if (emailSchedule.status === "scheduled") {
        navigate("/emailManagement/mail/scheduledEmailList");
      } else {
        navigate("/emailManagement/mail/sentEmailList");
      }
    } else {
      toast.error("メールの削除に失敗しました。");
      toggleModal("delete", false);
    }
  };

  // 送信取り消しボタンで「はい」を押したとき、メールの送信取り消しを実行して画面を再表示。
  const handleCancel = async (): Promise<void> => {
    const data = await CancelMailDetailAPI(apiClient, {
      mailSendIds: [mailId],
    });
    if (data != null) {
      void refetch();
      toast.success("メールの取り消しに成功しました。");
      toggleModal("cancel", false);
    } else {
      toast.error("メールの取り消しに失敗しました。");
      toggleModal("cancel", false);
    }
  };

  // 複製ボタンを押したとき、メールを複製し編集画面に遷移。
  const handleDuplicate = async (): Promise<void> => {
    const data = await DuplicateMailDetailAPI(apiClient, {
      mailSendId: mailId,
    });
    if (data != null) {
      toast.success("メールの複製に成功しました。");
      navigate(`/emailManagement/mail/edit/${data.id}/`);
    } else {
      toast.error("メールの複製に失敗しました。");
    }
  };

  useEffect(() => {
    const clients: CustomerStatusDataGridRow[] = [];
    data?.clients?.map((client) => {
      return clients.push({
        id: client.customerManagementId,
        realEstateOwnerCustomerManagementId: client.customerManagementId,
        status: client.status ?? "", // Ensure status is always a string
        ownerName: client.ownerName ?? "",
        ownerAddress: client.ownerAddress ?? "",
        postcode: client.postcode ?? "",
        email: client.email ?? "",
        tel: client.tel ?? "",
        originalShozai: client.originalShozai ?? "",
        realEstateType: client.realEstateType ?? "",
        legalAffairsBureauRequestDate: client.legalAffairsBureauRequestDate,
        mochibunStr: client.mochibunStr ?? "",
        receptionReason: client.receptionReason ?? "",
        responsiblePersonName: client.responsiblePersonName ?? "",
        isOutOfTarget: client.isOutOfTarget ?? false,
      });
    });

    setEmailSchedule({
      status: data?.status ?? "saved",
      title: data?.title ?? "",
      body: data?.body ?? "",
      destinationType: data?.destinationType ?? DestinationTypeValues.ALL,
      destinationConditions: data?.destinationConditions ?? null,
      clients: data?.clients ?? null,
      scheduledSendAt:
        data?.scheduledSendAt != null
          ? data.scheduledSendAt
          : new Date().toLocaleString(),
    });
    setSendRows(clients);
  }, [data]);

  return (
    <MailPageTemplate>
      <form>
        {/* ページタイトル */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <PageTitle>メール詳細</PageTitle>
          <MailStatus
            status={
              MailStatusMapping[emailSchedule?.status] as
                | "保存"
                | "保存済み"
                | "未保存"
                | "送信待ち"
                | "送信取消済"
                | "送信済み"
            }
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            width: "60%",
            margin: "56px auto",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Label>件名</Label>
            <TextField
              fullWidth
              variant="standard"
              required
              disabled
              value={emailSchedule.title}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Label>本文</Label>
            </Box>
            <TextareaAutosize
              value={emailSchedule.body}
              placeholder="テンプレートを選択してください。"
              minRows={4}
              required
              disabled
            />
          </Box>
          <Box>
            <FormControl>
              <Label>送信先の選択</Label>
              <RadioGroup row value={emailSchedule.destinationType}>
                <FormControlLabel
                  value={DestinationTypeValues.ALL}
                  control={<Radio />}
                  label={DestinationTypeLabels.ALL}
                  disabled
                />
                <FormControlLabel
                  value={DestinationTypeValues.CONDITION}
                  control={<Radio />}
                  label={DestinationTypeLabels.CONDITION}
                  disabled
                />
                <FormControlLabel
                  value={DestinationTypeValues.CUSTOMER_SELECT}
                  control={<Radio />}
                  label={DestinationTypeLabels.CUSTOMER_SELECT}
                  disabled
                />
              </RadioGroup>
            </FormControl>

            <Box sx={{ mt: 3 }}>
              <CustomerListFeedGrid rows={sendRows} />
            </Box>
          </Box>
          <Box>
            <Label>送信日時</Label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                disabled
                value={emailSchedule.scheduledSendAt}
                onChange={() => {
                  console.log();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            {emailSchedule.status === "saved" ||
            emailSchedule.status === "scheduled" ? (
              <Button
                label="編集"
                onClick={() => {
                  navigate(`/emailManagement/mail/edit/${mailId}/`);
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
            ) : null}
            {emailSchedule.status === "saved" ||
            emailSchedule.status === "sent" ? (
              <Button
                label="削除"
                onClick={() => {
                  toggleModal("delete", true);
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
                color={ButtonColorOption.Error}
              />
            ) : null}
            {emailSchedule.status === "scheduled" ? (
              <Button
                label="送信取り消し"
                onClick={() => {
                  toggleModal("cancel", true);
                }}
                variant={ButtonVariantOption.Outlined}
                size={SizingWrapperStyle.SMALL}
              />
            ) : null}
            <Button
              label="複製"
              onClick={() => {
                (async () => {
                  await handleDuplicate();
                })();
              }}
              variant={ButtonVariantOption.Outlined}
              size={SizingWrapperStyle.SMALL}
            />
          </Box>
        </Box>
      </form>

      {/* 削除確認モーダル */}
      <CustomModal
        isOpen={isModalOpen.delete}
        handleClose={() => {
          toggleModal("delete", false);
        }}
        ariaLabelledby="delete-modal"
        ariaDescribedby="delete-modal-description"
      >
        <PageTitle>削除確認</PageTitle>
        <Stack my={2} direction={"column"} spacing={1}>
          <Typography variant="body1">メールを削除します。</Typography>
          <Typography variant="body1">よろしいですか？</Typography>
          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              label="はい"
              onClick={() => {
                (async () => {
                  await handleDelete();
                })();
              }}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              onClick={() => {
                toggleModal("delete", false);
              }}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Stack>
      </CustomModal>

      {/* 送信取り消し確認モーダル */}
      <CustomModal
        isOpen={isModalOpen.cancel}
        handleClose={() => {
          toggleModal("cancel", false);
        }}
        ariaLabelledby="cancel-modal"
        ariaDescribedby="cancel-modal-description"
      >
        <PageTitle>送信取り消し確認</PageTitle>
        <Stack my={2} direction={"column"} spacing={1}>
          <Typography variant="body1">メールの送信を取り消します。</Typography>
          <Typography variant="body1">よろしいですか？</Typography>
          <Box
            sx={{
              mr: 2,
              my: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              label="はい"
              onClick={() => {
                (async () => {
                  await handleCancel();
                })();
              }}
              variant={ButtonVariantOption.Contained}
            />
            <Button
              label="いいえ"
              onClick={() => {
                toggleModal("cancel", false);
              }}
              variant={ButtonVariantOption.Outlined}
            />
          </Box>
        </Stack>
      </CustomModal>
    </MailPageTemplate>
  );
};
